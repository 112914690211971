import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';

export interface ITableRowProps {
    active?: boolean;
    hover?: boolean;
}

export const TableRow: React.FC<PropsWithChildren<ITableRowProps> & React.HTMLProps<HTMLTableRowElement>> = ({
    className = '',
    active = false,
    hover = false,
    children,
    ...otherProps
}) => {
    const classes = new ClassNameBuilder(className);

    classes.add('active', active);
    classes.add('hover', hover);

    return (
        <>
            <tr className={classes.build()} {...otherProps}>
                {children}
            </tr>
        </>
    );
};
