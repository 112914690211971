import * as React from 'react';
import { useListNavigation } from './useListNavigation';
import { getElementId } from '../../utils/getElementId';
import { ComboboxItemPropsKey } from '../models/ComboboxItemPropsKey';
import { IComboboxItemOptionProps } from '../models/IComboboxItemOptionProps';
import { IComboboxProps } from '../Combobox';
import { IComboboxItemProps } from '../ComboboxItem';
import { IComboboxListProps } from '../ComboboxList';
import { IComboboxDropdownProps } from '../ComboboxDropdown';
import { IComboboxButtonProps } from '../ComboboxButton';
import { IComboboxClearIconProps } from '../ComboboxClearIcon';
import { IComboboxInputProps } from '../ComboboxInput';

export interface IUseCombobox<T> {
    options: T[];
    onChange: (value: string) => void;
    listNavigationFn?: (options: T[]) => readonly [T, () => void, () => void, () => void, () => void, () => void];
    findItemFn?: (key: ComboboxItemPropsKey) => string;
    isInvalidItem?: (item: T) => boolean;
    defaultText?: string;
    onSelect?: (item: T) => void;
    creatable?: boolean;
}

export function useCombobox<T extends IComboboxItemOptionProps>({
    onChange,
    options,
    findItemFn,
    listNavigationFn = useListNavigation,
    isInvalidItem = () => false,
    defaultText = '',
    onSelect,
    creatable,
}: IUseCombobox<T>) {
    const listId = getElementId('combobox-list');

    const [expanded, setExpanded] = React.useState(false);
    const [inputText, setInputText] = React.useState(defaultText);
    const [focusedItem, clearFocusedItem, focusNext, focusPrevious] = listNavigationFn(options);

    const inputRef = React.useRef(null);

    React.useEffect(() => {
        onChange(inputText);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- adding onChange as dependency will trigger another call
    }, [inputText]);

    React.useEffect(() => {
        setInputText(defaultText);
    }, [defaultText]);

    const keyNavigationFactoryInput = new Map<string, () => void>([
        [
            'ArrowDown',
            () => {
                {
                    if (!expanded) {
                        setExpanded(true);
                    }
                    focusNext();
                }
            },
        ],
        ['ArrowUp', focusPrevious],
        ['Escape', () => setExpanded(false)],
        [
            'Enter',
            () => {
                if (focusedItem && expanded) {
                    onChooseItem(focusedItem);
                } else if (creatable) {
                    onChooseItem({ key: inputText, text: inputText } as T);
                }
            },
        ],
    ]);

    const keyNavigationFactoryButton = new Map<string, () => void>([
        [
            'ArrowDown',
            () => {
                {
                    if (!expanded) {
                        setExpanded(true);
                        inputRef.current?.focus();
                        focusNext();
                    }
                }
            },
        ],
        ['Escape', () => setExpanded(false)],
    ]);

    const onChooseItem = (item: T) => {
        if (!item || isInvalidItem(item)) {
            return;
        }

        const text = findItemFn ? findItemFn(item.key) : options.find((option) => option.key === item.key)?.text;
        setInputText(text);
        setExpanded(false);
        inputRef.current?.focus();

        if (onSelect) {
            onSelect(item);
        }
    };

    const clearItem = () => {
        setInputText('');
        clearFocusedItem();
        inputRef.current?.focus();
    };

    const onInputChange = (text: string) => {
        if (!expanded) {
            setExpanded(true);
        }
        setInputText(text);
    };

    const getInputProps = (): IComboboxInputProps & React.RefAttributes<HTMLInputElement> => ({
        ref: inputRef,
        value: inputText,
        onInputChange,
        onInputKeyDown: (keyboardKey: string) => {
            return keyNavigationFactoryInput.has(keyboardKey) && keyNavigationFactoryInput.get(keyboardKey)();
        },
        onClick: () => {
            setExpanded(!expanded);
            inputRef.current?.focus();
        },
        ariaActivedescendant: focusedItem ? `${listId}-item-idx-${focusedItem.key}` : '',
    });

    const getClearIconProps = (): IComboboxClearIconProps => ({
        onClearClick: clearItem,
        isVisible: inputText !== '',
    });

    const getButtonProps = (): IComboboxButtonProps => ({
        onButtonKeyDown: (keyboardKey: string) => {
            return keyNavigationFactoryButton.has(keyboardKey) && keyNavigationFactoryButton.get(keyboardKey)();
        },
        onClick: () => {
            setExpanded(!expanded);
            inputRef.current?.focus();
        },
    });

    const getDropDownProps = (): IComboboxDropdownProps => ({
        expanded,
    });

    const getListProps = (): IComboboxListProps => ({
        id: listId,
        expanded,
    });

    const getComboboxProps = (): IComboboxProps => ({
        ariaControls: listId,
        expanded,
    });

    const getItemProps = (item: T): IComboboxItemProps => ({
        hasFocus: item?.key === focusedItem?.key,
        itemKey: item.key,
        onClickItem: () => onChooseItem(item),
        id: `${listId}-item-idx-${item.key}`,
    });

    const getWrapperProps = () => ({
        setExpanded,
    });

    return [
        inputText,
        getInputProps,
        getClearIconProps,
        getButtonProps,
        getDropDownProps,
        getListProps,
        getComboboxProps,
        getItemProps,
        getWrapperProps,
        setExpanded,
        clearItem,
    ] as const;
}
