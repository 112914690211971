import * as React from 'react';
import { ComboboxItemDisplayCaseInsensitive } from './ComboboxItemDisplayCaseInsensitive';
import { useCombobox } from './hooks/useCombobox';
import { Combobox } from './Combobox';
import { ComboboxWrapper } from './ComboboxWrapper';
import { ComboboxButton } from './ComboboxButton';
import { ComboboxList } from './ComboboxList';
import { ComboboxDropdown } from './ComboboxDropdown';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxClearIcon } from './ComboboxClearIcon';
import { ComboboxItem } from './ComboboxItem';
import { IComboboxItemOptionProps } from './models/IComboboxItemOptionProps';
import { ComboboxItemSelected } from './ComboboxItemSelected';
import { Icon } from '../Icons';
import { Button } from '../Buttons';
import { usePopper } from 'react-popper';

export interface IComboboxMultipleProps {
    onInputChange?: (value: string) => void;
    options: IComboboxItemOptionProps[];
    className?: string;
    name?: string;
    onChange: (newValue: IComboboxItemOptionProps[]) => void;
    value: IComboboxItemOptionProps[];
    hasError?: boolean;
    placeholder?: string;
    dropdownAriaLabel?: string;
    clearAriaLabel?: string;
    noSuggestionLabel?: string;
    listHeader?: string | React.ReactNode;
    listAriaLabel?: string;
    removeBadgeLabel?: (text: string) => string | React.ReactNode;
    creatable?: boolean;
    getCreateLabel?: (inputText: string) => string | React.ReactNode;
}

export const ComboboxMultiple: React.FC<
    IComboboxMultipleProps & Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'ref' | 'onChange' | 'onClick'>
> = ({
    placeholder = '',
    options,
    noSuggestionLabel,
    listHeader,
    clearAriaLabel = 'Delete selection in input',
    dropdownAriaLabel = 'Expand dropdown',
    listAriaLabel = 'Choose from list',
    onInputChange,
    value = [],
    onChange,
    removeBadgeLabel,
    creatable = false,
    getCreateLabel,
    className = '',
    hasError = false,
    ...otherInputProps
}) => {
    const [inputItems, setInputItems] = React.useState(options);
    const [selectedValues, setSelectedValues] = React.useState<IComboboxItemOptionProps[]>(value);

    const [
        inputText,
        getInputProps,
        getClearIconProps,
        getButtonProps,
        getDropDownProps,
        getListProps,
        getComboboxProps,
        getItemProps,
        getWrapperProps,
        ,
        clearItem,
    ] = useCombobox({
        options: inputItems,
        onChange: (text: string) => {
            setInputItems(
                options?.filter(
                    (item) =>
                        !selectedValues?.some((selectedItem) => selectedItem.key === item.key) &&
                        item.text.toLowerCase().includes(text?.toLowerCase()),
                ),
            );
            if (onInputChange) onInputChange(text);
        },
        onSelect(item: IComboboxItemOptionProps) {
            const newSelectedValues = [...selectedValues, item];
            setSelectedValues(newSelectedValues);
            setInputItems(options?.filter((option) => !newSelectedValues.some((selectedItem) => selectedItem.key === option.key)));
            onChange(newSelectedValues);

            clearItem();
        },
        creatable,
        isInvalidItem: (selectedItem: IComboboxItemOptionProps) => {
            return creatable && selectedValues?.some((item) => item.key === selectedItem.key);
        },
    });

    const isCreatable = React.useMemo(
        () =>
            creatable &&
            inputText &&
            !options.some((item) => item.text === inputText) &&
            !selectedValues?.some((item) => item.text === inputText),
        [creatable, inputText, selectedValues, options],
    );

    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'absolute',
        placement: 'bottom-end',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 1],
                },
            },
        ],
    });

    return (
        <ComboboxWrapper {...getWrapperProps()} className={className}>
            <Combobox {...getComboboxProps()} hasError={hasError} ref={setReferenceElement}>
                {selectedValues?.length > 0 &&
                    selectedValues.map(
                        (item) =>
                            item && (
                                <ComboboxItemSelected
                                    key={item.key}
                                    label={item.text}
                                    closeLabel={(text) => removeBadgeLabel && removeBadgeLabel(text)}
                                    onRemove={() => {
                                        const newSelectedValues = selectedValues.filter((selectedItem) => selectedItem.key !== item.key);
                                        setSelectedValues(newSelectedValues);
                                        onChange(newSelectedValues);

                                        if (options.some((option) => option.text === item.text)) {
                                            setInputItems([...inputItems, item]);
                                        }
                                    }}
                                />
                            ),
                    )}
                <ComboboxInput {...getInputProps()} placeholder={placeholder} {...otherInputProps} />
            </Combobox>
            <ComboboxClearIcon {...getClearIconProps()} aria-label={clearAriaLabel} />
            <ComboboxButton {...getButtonProps()} aria-label={dropdownAriaLabel} />
            <ComboboxDropdown
                {...getDropDownProps()}
                header={listHeader}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                <ComboboxList {...getListProps()} noSuggestionLabel={noSuggestionLabel} aria-label={listAriaLabel}>
                    {inputItems?.length > 0 &&
                        inputItems
                            .sort((a, b) => a.text.localeCompare(b.text))
                            .map((item) => (
                                <ComboboxItem {...getItemProps(item)} key={item.key}>
                                    <ComboboxItemDisplayCaseInsensitive filter={inputText} value={item.text} />
                                </ComboboxItem>
                            ))}

                    {isCreatable && (
                        <li className="combobox-list-item d-flex align-items-center" key="add-new-item">
                            <Button
                                buttonStyle="link"
                                className="text-no-underline m-0 p-0 w-100 justify-content-start"
                                onClick={() => {
                                    const newSelectedValues = [
                                        ...selectedValues,
                                        {
                                            key: inputText,
                                            text: inputText,
                                        },
                                    ];
                                    setSelectedValues(newSelectedValues);
                                    setInputItems(
                                        options?.filter(
                                            (option) => !newSelectedValues?.some((selectedItem) => selectedItem.key === option.key),
                                        ),
                                    );
                                    onChange(newSelectedValues);

                                    clearItem();
                                }}
                            >
                                <Icon name="add" size="sm" /> {getCreateLabel && getCreateLabel(inputText)}
                            </Button>
                        </li>
                    )}
                </ComboboxList>
            </ComboboxDropdown>
        </ComboboxWrapper>
    );
};
