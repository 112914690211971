import { makeStyles, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import {
    ChevronDoubleLeftRegular,
    ChevronDoubleRightRegular,
    ChevronLeftRegular,
    ChevronRightRegular,
} from '@fluentui/react-icons';

interface TablePaginationProps {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalItems: number;
    onPageChange: (page: number) => void;
}

const useStyles = makeStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        borderTop: '1px solid #ddd',
        backgroundColor: 'white',
        flexShrink: 0,
        gap: '4px',
    },
    button: {
        padding: '4px 8px',
        border: '1px solid #ddd',
        borderRadius: tokens.borderRadiusXLarge,
        backgroundColor: 'white',
        cursor: 'pointer',
        minWidth: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover:not(:disabled)': {
            backgroundColor: 'var(--spcs-purple-05)',
        },
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 0.5,
        },
    },
    pageInfo: {
        padding: '4px 8px',
        backgroundColor: 'white',
        minWidth: '200px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const TablePagination = ({
    currentPage,
    totalPages,
    pageSize,
    totalItems,
    onPageChange,
}: TablePaginationProps): JSX.Element => {
    const classes = useStyles();
    const { t } = useTranslation();

    const startRecord = totalItems === 0 ? 0 : (currentPage - 1) * pageSize + 1;
    const endRecord = Math.min(currentPage * pageSize, totalItems);

    return (
        <div className={classes.pagination}>
            <button
                className={classes.button}
                onClick={() => {
                    onPageChange(1);
                }}
                disabled={currentPage === 1}
                title={t('datatable.paginate.first')}
            >
                <ChevronDoubleLeftRegular />
            </button>
            <button
                className={classes.button}
                onClick={() => {
                    onPageChange(currentPage - 1);
                }}
                disabled={currentPage === 1}
                title={t('datatable.paginate.previous')}
            >
                <ChevronLeftRegular />
            </button>
            <div className={classes.pageInfo}>
                {t('datatable.paginate.showing', { start: startRecord, end: endRecord, total: totalItems })}
            </div>
            <button
                className={classes.button}
                onClick={() => {
                    onPageChange(currentPage + 1);
                }}
                disabled={currentPage === totalPages}
                title={t('datatable.paginate.next')}
            >
                <ChevronRightRegular />
            </button>
            <button
                className={classes.button}
                onClick={() => {
                    onPageChange(totalPages);
                }}
                disabled={currentPage === totalPages}
                title={t('datatable.paginate.last')}
            >
                <ChevronDoubleRightRegular />
            </button>
        </div>
    );
};

export default TablePagination;
