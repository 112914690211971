import * as React from 'react';
import { getElementId } from '../utils/getElementId';
import { Panel } from '../Panel';
import { clsx } from 'clsx';

export interface IListGroupProps {
    title?: string | React.ReactNode;
}

export const ListGroup: React.FC<IListGroupProps> & React.HTMLProps<HTMLDivElement> = ({ children, title }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const titleId = getElementId(null);
    const collapseId = getElementId(null);

    return (
        <Panel role="tab">
            <div className="panel-heading" id={titleId}>
                <h4 className="panel-title">
                    <a
                        className={clsx('collapsed', '' && isOpen)}
                        role="link"
                        data-toggle="collapse"
                        aria-expanded={isOpen}
                        aria-controls={collapseId}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {title} <span className={clsx('caret caret-large-primary', 'open' && isOpen)} />
                    </a>
                </h4>
            </div>
            <div
                id={collapseId}
                className={clsx(`panel-collapse collapse`, { in: isOpen })}
                role="tabpanel"
                aria-labelledby={titleId}
                aria-expanded={isOpen}
                aria-hidden={!isOpen}
            >
                {children}
            </div>
        </Panel>
    );
};
