import * as React from 'react';
import { clsx } from 'clsx';

export interface ICardsListItemIconProps {
    className?: string;
    icon?: string;
}

export const CardsListItemIcon: React.FC<React.PropsWithChildren<ICardsListItemIconProps>> = ({ children, icon, className }) => {
    return (
        <div className={clsx(className)}>
            <span className={`vismaicon vismaicon-${icon}`}></span>
            {children}
        </div>
    );
};
