import { makeStyles } from '@fluentui/react-components';
import { Search } from '@vismaux/react-vud';

interface TableSearchProps {
    searchTerm: string;
    onSearch: (term: string) => void;
    placeholder?: string;
}

const useStyles = makeStyles({
    search: {
        marginBottom: '0',
    },
});

const TableSearch = ({ searchTerm, onSearch, placeholder = 'Search...' }: TableSearchProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Search
            className={classes.search}
            placeholder={placeholder}
            searchType="primary"
            onInputChange={(value) => {
                onSearch(value);
            }}
            onSearch={() => {
                onSearch(searchTerm);
            }}
        />
    );
};

export default TableSearch;
