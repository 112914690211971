import { useCallback, useState } from 'react';
import { makeStyles, tokens } from '@fluentui/react-components';

import TablePagination from './TablePagination';
import TableSearch from './TableSearch';
import TablePageSize from './TablePageSize';
import { t } from 'i18next';

interface Column<_T> {
    name: string;
    title: string;
    type?: 'string' | 'number' | 'date' | 'boolean';
    sortable?: boolean;
    searchable?: boolean;
}

interface SortConfig {
    column: string;
    direction: 'asc' | 'desc';
}

interface PaginationConfig {
    pageSize: number;
    currentPage: number;
    totalItems: number;
}

interface DataTableProps<T extends Record<string, unknown>> {
    columns: Array<Column<T>>;
    data: T[];
    totalItems: number;
    onPageChange: (page: number) => void;
    onSort: (column: string, direction: 'asc' | 'desc') => void;
    onSearch: (searchTerm: string) => void;
    onPageSizeChange: (pageSize: number) => void;
    isLoading?: boolean;
    selectable?: boolean;
    onSelectionChange?: (selectedItems: T[]) => void;
}

const useStyles = makeStyles({
    tableWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    controlsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: tokens.spacingHorizontalL,
        borderBottom: '1px solid #ddd',
        flexShrink: 0,
        gap: tokens.spacingHorizontalL,
    },
    controlsLeft: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalL,
    },
    controlsRight: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalL,
    },
    tableContainer: {
        flex: 1,
        overflow: 'auto',
        position: 'relative',
        minHeight: 0, // This is important for Firefox
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: 'white',
    },
    th: {
        padding: tokens.spacingHorizontalM,
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        userSelect: 'none',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '&:hover': {
            backgroundColor: 'var(--spcs-purple-05)',
        },
    },
    td: {
        padding: tokens.spacingHorizontalM,
        borderBottom: '1px solid #ddd',
    },
    tr: {
        '&:nth-child(odd)': {
            backgroundColor: '#F5F4F0',
        },
        '&:hover': {
            backgroundColor: 'var(--spcs-purple-05)',
        },
    },
    selectedRow: {
        backgroundColor: '#e3f2fd',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: tokens.colorSubtleBackgroundLightAlphaHover,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
    },
    sortIcon: {
        marginLeft: '4px',
    },
});

const DataTable = <T extends Record<string, unknown>>({
    columns,
    data,
    totalItems,
    onPageChange,
    onSort,
    onSearch,
    onPageSizeChange,
    isLoading = false,
}: DataTableProps<T>): JSX.Element => {
    const classes = useStyles();
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [pagination, setPagination] = useState<PaginationConfig>({
        pageSize: 10,
        currentPage: 1,
        totalItems,
    });

    const totalPages = Math.ceil(totalItems / pagination.pageSize);

    const handleSort = useCallback(
        (column: string) => {
            const newDirection = sortConfig?.column === column && sortConfig.direction === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column, direction: newDirection });
            onSort(column, newDirection);
        },
        [sortConfig, onSort],
    );

    const handleSearch = useCallback(
        (value: string) => {
            setSearchTerm(value);
            onSearch(value);
        },
        [onSearch],
    );

    const handlePageChange = useCallback(
        (page: number) => {
            setPagination((prev) => ({ ...prev, currentPage: page }));
            onPageChange(page);
        },
        [onPageChange],
    );

    const handlePageSizeChange = useCallback(
        (newPageSize: number) => {
            setPagination((prev) => ({ ...prev, pageSize: newPageSize, currentPage: 1 }));
            onPageSizeChange(newPageSize);
        },
        [onPageSizeChange],
    );

    const renderSortIcon = useCallback(
        (column: string) => {
            if (sortConfig?.column !== column) {
                return null;
            }
            return <span className={classes.sortIcon}>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>;
        },
        [sortConfig, classes.sortIcon],
    );

    return (
        <div className={classes.tableWrapper}>
            <div className={classes.controlsRow}>
                <div className={classes.controlsLeft}>
                    <TableSearch searchTerm={searchTerm} placeholder={t('datatable.search')} onSearch={handleSearch} />
                </div>
                <div className={classes.controlsRight}>
                    <TablePageSize pageSize={pagination.pageSize} onPageSizeChange={handlePageSizeChange} />
                </div>
            </div>
            <div className={classes.tableContainer}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            {columns.map((column) => {
                                return (
                                    <th
                                        key={column.name}
                                        className={classes.th}
                                        onClick={() => {
                                            if (column.sortable) {
                                                handleSort(column.name);
                                            }
                                        }}
                                    >
                                        {column.title}
                                        {column.sortable && renderSortIcon(column.name)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            return (
                                <tr key={index} className={classes.tr}>
                                    {columns.map((column) => {
                                        return (
                                            <td key={column.name} className={classes.td}>
                                                {item[column.name] != null ? String(item[column.name]) : ''}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {isLoading && (
                    <div className={classes.overlay}>
                        <div>{t('datatable.loadingRecords')}</div>
                    </div>
                )}
                {data.length === 0 && !isLoading && <div className={classes.overlay}>{t('datatable.zeroRecords')}</div>}
            </div>
            <TablePagination
                currentPage={pagination.currentPage}
                totalPages={totalPages}
                pageSize={pagination.pageSize}
                totalItems={totalItems}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default DataTable;
