import * as React from 'react';
import { useClickAway } from 'react-use';
import { clsx } from 'clsx';

export interface IComboboxWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    setExpanded: (isExpanded: boolean) => void;
}

export const ComboboxWrapper: React.FC<React.PropsWithChildren<IComboboxWrapperProps>> = ({
    className = '',
    setExpanded,
    children,
    ...rest
}: IComboboxWrapperProps) => {
    const ref = React.useRef<HTMLDivElement>();
    useClickAway(ref, () => setExpanded(false));

    return (
        <div className={clsx(className, 'combobox-wrapper')} ref={ref} {...rest}>
            {children}
        </div>
    );
};
