import * as React from 'react';
import { PropsWithChildren, ReactNode } from 'react';

interface IMenuUserLinkProps {
    name: string | ReactNode;
    onClick: () => void;
    expanded?: boolean;
    description?: string;
}

export const NavbarNavMenuUserLink = (props: PropsWithChildren<IMenuUserLinkProps>) => (
    <a
        rel="noopener noreferrer"
        className="dropdown-toggle button-context"
        onClick={(event: any) => {
            event.stopPropagation();
            event.preventDefault();
            props.onClick();
        }}
        data-toggle="dropdown"
        aria-expanded={props.expanded}
        role="menuitem"
    >
        {props.name}
        <small>{props.description}</small>
        <span className="vismaicon vismaicon-menu vismaicon-user" />
        <span className="caret" />
    </a>
);
