import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type HorizontalLoadingBarSizeType = 'sm' | 'xs';

export interface IHorizontalLoadingBarProps {
    className?: string;
    size?: HorizontalLoadingBarSizeType;
    arialLabel?: string | React.ReactElement;
}


export const HorizontalLoadingBar: React.FC<IHorizontalLoadingBarProps> & React.HTMLProps<HTMLDivElement> = ({
    className,
    size,
    children,
    arialLabel,
}) => {
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('horizontal-loading').add(`horizontal-loading-${size}`, isDefined(size));

    return (
        <div className={classBuilder.build()}>
            <div className="loading-bar" role="progressbar">
                {arialLabel && <span className="sr-only">{arialLabel}</span>}
            </div>
            {children}
        </div>

    );
}
;
