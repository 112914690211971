import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { t } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import charBubbleArrowDisabled from '../../assets/bot-icons/chat-bubble-tip-disabled.svg';
import charBubbleArrow from '../../assets/bot-icons/chat-bubble-tip.svg';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { editConversationInput } from '../../redux/features/conversations/conversationsSlice';
import { AuthorRoles } from '../../libs/models/ChatMessage';
import { Alerts } from '../shared/Alerts';
import { ChatStatus } from './ChatStatus';
import Icon from '../utils/Icon';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    rootEmbedded: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    typingIndicator: {
        maxHeight: '28px',
        width: '60%',
        '@media (min-width:1100px) and (max-width:1700px)': {
            width: '80%',
        },
        '@media (max-width:1100px)': {
            width: '100%',
        },
    },
    content: {
        position: 'relative',
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    TextareaAutosize: {
        ...shorthands.padding('5px', '70px', '5px', '15px'),
        ...shorthands.overflow('hidden'),
        ...shorthands.border('1px', 'solid', '#8A8A8A'),
        ...shorthands.borderRadius('10px'),
        ...shorthands.outline('0'),
        width: '100%',
        resize: 'none',
    },
    textareaDisabled: {
        backgroundColor: '#F3F3F3',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
    },
    dragAndDrop: {
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    userIcon: {
        alignSelf: 'flex-end',
        marginRight: '1rem',
        marginBottom: '10.5px',
    },
    arrow: {
        alignSelf: 'flex-end',
        backgroundImage: `url(${charBubbleArrow})`,
        height: '40px',
        width: '40px',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        left: '-10px',
        marginBottom: '10.5px',
        zIndex: 1,
    },
    arrowDisabled: {
        backgroundImage: `url(${charBubbleArrowDisabled})`,
    },
    sendButton: {
        alignSelf: 'flex-end',
        position: 'absolute',
        right: '9.5px',
        bottom: '9.5px',
        maxWidth: 'none',
        width: '47px',
        height: '47px',
    },
    sendButtonIcon: {
        color: '#494A4A',
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        maxWidth: '900px',
        width: '100%',
        '@media (min-width:1100px) and (max-width:1700px)': {
            width: '80%',
        },
        '@media (max-width:1100px)': {
            width: '100%',
        },
    },
    disclaimer: {
        fontSize: '10px',
        maxWidth: '900px',
        width: '100%',
        '@media (min-width:1100px) and (max-width:1700px)': {
            width: '80%',
        },
        '@media (max-width:1100px)': {
            width: '100%',
        },
    },
});

interface ChatInputProps {
    handleSubmit: (text: string) => void;
}

export const ChatInput: React.FC<ChatInputProps> = ({ handleSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { V2ExperimentalFeatures } = useAppSelector((state: RootState) => state.app.serviceInfo.featureFlags);

    const { embeddedMode } = useAppSelector((state: RootState) => state.app);
    const messages = conversations[selectedId].messages;
    //const fileHandler = useFile();

    const [value, setValue] = useState('');
    const [historyIndex, setHistoryIndex] = useState(-1);

    const userMessages = useMemo(
        () => messages.filter((msg) => msg.authorRole === AuthorRoles.User).map((msg) => msg.content),
        [messages],
    );

    //const documentFileRef = useRef<HTMLInputElement | null>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        // Focus on the text area when the component mounts / page loads
        textAreaRef.current?.focus();
    }, [textAreaRef]);

    useEffect(() => {
        // Focus on the text area when the selected conversation changes
        textAreaRef.current?.focus();
    }, [selectedId]);

    const { disabled: chatStateDisabled, input: chatStateInput } = useMemo(
        () => conversations[selectedId],
        [conversations, selectedId],
    );

    useEffect(() => {
        setValue(chatStateDisabled ? '' : chatStateInput);

        // Focus on the text area when a response has been received
        if (!chatStateDisabled) {
            textAreaRef.current?.focus();
        }
    }, [chatStateDisabled, chatStateInput]);

    const updateInputWithHistory = (newIndex: number) => {
        setHistoryIndex(newIndex);
        const historicalInput = newIndex === -1 ? '' : userMessages[newIndex];
        setValue(historicalInput);
        dispatch(editConversationInput({ id: selectedId, newInput: historicalInput }));
    };

    const navigateHistory = (direction: 'up' | 'down') => {
        if (direction === 'up' && userMessages.length > 0) {
            const newIndex = historyIndex === -1 ? userMessages.length - 1 : Math.max(0, historyIndex - 1);
            updateInputWithHistory(newIndex);
        } else if (direction === 'down' && historyIndex >= 0) {
            const newIndex = historyIndex + 1;
            updateInputWithHistory(newIndex >= userMessages.length ? -1 : newIndex);
        }
    };

    const handleSubmitWrapper = (text: string) => {
        // Reset the closed state when submitting a new message
        handleSubmit(text);
    };

    return (
        <div className={embeddedMode ? classes.rootEmbedded : classes.root}>
            {!V2ExperimentalFeatures && (
                <div className={classes.typingIndicator}>
                    <ChatStatus />
                </div>
            )}
            <Alerts />
            <div className={classes.inputWrapper}>
                <div className={classes.content}>
                    <TextareaAutosize
                        minRows={3}
                        maxRows={10}
                        title={t('chatInput.textAreaTitle')}
                        aria-label={t('chatInput.textAreaAriaLabel')}
                        ref={textAreaRef}
                        id="chat-input"
                        rows={1}
                        placeholder={messages.length > 1 ? t('chatInput.replay') : t('chatInput.askAQuestion')}
                        disabled={conversations[selectedId].disabled}
                        className={`${classes.TextareaAutosize} ${conversations[selectedId].disabled ? classes.textareaDisabled : ''}`}
                        value={value}
                        onFocus={() => {
                            // update the locally stored value to the current value
                            const chatInput = document.getElementById('chat-input');
                            if (chatInput) {
                                setValue((chatInput as HTMLTextAreaElement).value);
                            }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            setValue(e.target.value);
                            dispatch(editConversationInput({ id: selectedId, newInput: e.target.value }));
                        }}
                        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleSubmitWrapper(value);
                                setHistoryIndex(-1);
                            } else if (event.key === 'ArrowUp' && !event.shiftKey) {
                                event.preventDefault();
                                navigateHistory('up');
                            } else if (event.key === 'ArrowDown' && !event.shiftKey) {
                                event.preventDefault();
                                navigateHistory('down');
                            }
                        }}
                    />
                    <Button
                        title={t('chatInput.submitButtonTitle')}
                        aria-label={t('chatInput.submitButtonAriaLabel')}
                        appearance="transparent"
                        icon={<Icon name="paperplane" className={classes.sendButtonIcon} />}
                        className={classes.sendButton}
                        onClick={() => {
                            handleSubmitWrapper(value);
                        }}
                        disabled={conversations[selectedId].disabled}
                    />
                </div>
            </div>
            <div className={classes.disclaimer}>{t('chatInput.disclaimer')}</div>
        </div>
    );
};
