import * as React from 'react';
import { getElementId } from '../utils/getElementId';
import { Panel, PanelBody } from '../Panel';
import { clsx } from 'clsx';

export interface IListGroupItemProps {
    title?: string | React.ReactNode;
}

export const ListGroupItem: React.FC<IListGroupItemProps> & React.HTMLProps<HTMLDivElement> = ({ title, children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const titleId = getElementId(null);

    return (
        <>
            <PanelBody>
                <Panel>
                    <div className="panel-heading" id={titleId}>
                        <h4 className="panel-title">
                            <a
                                className={clsx('collapsed', '' && isOpen)}
                                data-toggle="collapse"
                                aria-expanded={isOpen}
                                aria-controls={titleId}
                                onClick={() => setIsOpen(!isOpen)}
                                role="link"
                            >
                                <span className="caret" />
                                {title}
                            </a>
                        </h4>
                    </div>
                    <div className={clsx(`panel-collapse collapse`, { in: isOpen })} role="tabpanel" aria-labelledby={titleId}>
                        <PanelBody>{children}</PanelBody>
                    </div>
                </Panel>
            </PanelBody>
        </>
    );
};
