import * as React from 'react';
import { clsx } from 'clsx';

export interface ICardsListItemProps {
    className?: string;
}

export const CardsListItem: React.FC<React.PropsWithChildren<ICardsListItemProps>> = ({ children, className }) => {
    return <div className={clsx(className, 'card-list-item row')}>{children}</div>;
};
