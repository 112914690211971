import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type BadgeType = 'success' | 'warning' | 'danger' | 'info';

export interface IBadgeProps {
    type?: BadgeType;
}

export const Badge: React.FC<PropsWithChildren<IBadgeProps> & React.HTMLProps<HTMLSpanElement>> = ({
    className,
    type,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className || '');

    classBuilder.add('badge').add(`badge-${type}`, isDefined(type));

    return (
        <span className={classBuilder.build()} {...others}>
            {children}
        </span>
    );
};
