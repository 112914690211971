import * as React from 'react';

interface IIconProps {
    title: string;
    icon: 'settings' | string;
    attention?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    href?: string;
    target?: string;
}

export const NavbarNavMenuIcon = (props: IIconProps) => {
    const id = props.title.replace(' ', '_').toLowerCase();
    return (
        <>
            <li role="none" className={`icon ${id} ${props.attention ? 'attention' : ''}`}>
                <a {...props} rel="noopener noreferrer" role="menuitem" title={props.title} id={props.title.toLowerCase()}>
                    <span className={`vismaicon vismaicon-menu vismaicon-${props.icon}`} />
                </a>
            </li>
        </>
    );
};
