import { IFileUploadAttachment } from '../models';

export enum FileUploadError {
    UnsupportedFormat = 'unsupported-format',
    Duplicate = 'duplicate',
    FileTooLarge = 'too-large',
}

export interface FileVerifyError {
    type: FileUploadError;
    affectedFiles: string[];
    languageProps?: object;
}

export interface FileValidationProps {
    supportedFileMimeTypes: string[];
    fileExtensions: string[];
    maxFileSizeMb: number;
    files: IFileUploadAttachment[];
}

const useFileValidation = ({ supportedFileMimeTypes, fileExtensions, maxFileSizeMb, files }: FileValidationProps) => {
    const getValidFiles = (pendingFiles: File[]): [File[], FileVerifyError[]] => {
        const errors: FileVerifyError[] = [];
        const addErrors = (errorFiles: { name: string }[], type: FileUploadError, languageProps?: object) => {
            if (errorFiles?.length > 0) {
                errors.push({ type: type, affectedFiles: errorFiles.map((_) => _.name), languageProps });
            }
        };

        addErrors(
            files
                ?.filter((stored) => pendingFiles.map((pending) => pending.name).includes(stored.canonicalName))
                ?.map((stored) => ({ name: stored.canonicalName })),
            FileUploadError.Duplicate,
        );
        addErrors(
            pendingFiles?.filter((pending) => supportedFileMimeTypes.indexOf(pending.type) === -1),
            FileUploadError.UnsupportedFormat,
            { extensions: fileExtensions?.join(', ') },
        );
        addErrors(
            pendingFiles?.filter((pending) => pending.size > maxFileSizeMb * 1024 * 1024),
            FileUploadError.FileTooLarge,
            { limit: maxFileSizeMb },
        );

        const validFiles = pendingFiles?.filter((pending) => !errors.some((error) => error.affectedFiles.includes(pending.name)));

        return [validFiles || [], errors];
    };

    return { getValidFiles };
};

export default useFileValidation;
