import * as React from 'react';
import { clsx } from 'clsx';

export interface ICardsListItemDateProps {
    className?: string;
}

export const CardsListItemDate: React.FC<React.PropsWithChildren<ICardsListItemDateProps>> = ({ children, className }) => {
    return <div className={clsx(className, 'date')}>{children}</div>;
};
