import React, { useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { formatChatMessage } from '../../utils/ChatMessageUtils';
import CustomLink from '../../utils/CustomLink';
import { ChatHistorySourceLinks } from './ChatHistorySourceLinks';
import { makeStyles } from '@fluentui/react-components';
import { containsLatex, AiaLatex } from '../../utils/AiaLatex';

interface ChatHistoryTextContentProps {
    message: IChatMessage;
    isLastMessage: boolean;
    isChatResponseComplete: boolean;
    isUser: boolean;
    v2ExperimentalFeatures: boolean;
}

const useClasses = makeStyles({
    content: {
        '& *:first-child': {
            marginTop: '0',
        },
        '& *:last-child': {
            marginBottom: '0',
        },
    },
});

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = React.memo(
    ({ message, isLastMessage, isChatResponseComplete, isUser, v2ExperimentalFeatures }) => {
        const content = useMemo(() => formatChatMessage(message), [message]);
        const classes = useClasses();
        const hasLatex = useMemo(() => containsLatex(content), [content]);
        const showLinks = useMemo(() => {
            return (
                (isChatResponseComplete || !isLastMessage) &&
                message.contentMetadataArray?.contentMetadata.filter((x) => x.orderOfAppearance > 0).pop()
            );
        }, [isChatResponseComplete, isLastMessage, message.contentMetadataArray]);

        const renderContent = useCallback(() => {
            if (isUser) {
                return <div>{content}</div>;
            }

            if (hasLatex) {
                return (
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        className={classes.content}
                        components={{
                            a: (props) => <CustomLink {...props} message={message} isLastMessage={isLastMessage} />,
                            p: ({ ...props }) => {
                                const textContent = props.children?.toString() ?? '';
                                return containsLatex(textContent) ? (
                                    <AiaLatex content={textContent} />
                                ) : (
                                    <p {...props} />
                                );
                            },
                            li: ({ ...props }) => {
                                const textContent = props.children?.toString() ?? '';
                                return containsLatex(textContent) ? (
                                    <li>
                                        <AiaLatex content={textContent} />
                                    </li>
                                ) : (
                                    <li {...props} />
                                );
                            },
                        }}
                    >
                        {content}
                    </ReactMarkdown>
                );
            }

            return (
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    className={classes.content}
                    components={{
                        a: (props) => <CustomLink {...props} message={message} isLastMessage={isLastMessage} />,
                    }}
                >
                    {content}
                </ReactMarkdown>
            );
        }, [isUser, content, message, isLastMessage, classes.content, hasLatex]);

        return (
            <>
                {renderContent()}
                {message.graphData && v2ExperimentalFeatures && (
                    <div style={{ maxHeight: '600px' }}>{/* Render graph data here */}</div>
                )}
                {showLinks && (
                    <ChatHistorySourceLinks contentMetadata={message.contentMetadataArray?.contentMetadata} />
                )}
            </>
        );
    },
);

ChatHistoryTextContent.displayName = 'ChatHistoryTextContent';

export default ChatHistoryTextContent;
