import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';

export interface ITableProps {
    className?: string;
    active?: boolean;
    hover?: boolean;
    condensed?: boolean;
    responsive?: boolean;
}

export const Table: React.FC<PropsWithChildren<ITableProps> & React.TableHTMLAttributes<HTMLTableElement>> = ({
    className = '',
    active = false,
    hover = false,
    condensed = false,
    responsive = false,
    children,
    ...otherProps
}) => {
    const classes = new ClassNameBuilder(className);

    classes
        .add('table')
        .add('table-hover', hover)
        .add('table-active', active)
        .add('table-responsive', responsive)
        .add('table-condensed', condensed);

    return (
        <>
            <table className={classes.build()} {...otherProps}>
                {children}
            </table>
        </>
    );
};
