import { makeStyles, mergeClasses } from '@fluentui/react-components';

interface RadioOption {
    value: string;
    label: string;
}

interface RadioGroupProps {
    name: string;
    options: RadioOption[];
    selectedValue: string;
    onChange: (value: string) => void;
}

const useClasses = makeStyles({
    radio: {
        display: 'inline-block',
    },
});

const RadioGroup: React.FC<RadioGroupProps> = ({ name, options, selectedValue, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const classes = useClasses();

    return (
        <div>
            {options.map((option) => (
                <div className={mergeClasses('radio', classes.radio)} key={option.value}>
                    <input
                        type="radio"
                        id={`${name}_${option.value}`}
                        name={name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleChange}
                    />
                    <label htmlFor={`${name}_${option.value}`}>{option.label}</label>
                </div>
            ))}
        </div>
    );
};

export default RadioGroup;
