import * as React from 'react';
import { forwardRef, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

export interface IComboboxProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    hasError?: boolean;
    expanded: boolean;
    ariaControls: string;
}

export const Combobox = forwardRef<HTMLDivElement, PropsWithChildren<IComboboxProps>>(function Combobox(
    { className = '', children, hasError, expanded, ariaControls, ...rest },
    ref,
) {
    return (
        <div
            className={clsx(className, 'combobox', { 'has-error': hasError })}
            role="combobox"
            aria-expanded={expanded}
            aria-haspopup="listbox"
            aria-controls={ariaControls}
            {...rest}
            ref={ref}
        >
            {children}
        </div>
    );
});
