import * as React from 'react';
import { getElementId } from '../utils/getElementId';
import { useFilePreview } from './hooks/useFilePreview';
import { IFileUploadAttachment } from './models/IFileUploadAttachment';
import { clsx } from 'clsx';

export interface IDropAreaPreviewProps {
    attachments: IFileUploadAttachment[];
    onDelete: (attachment: IFileUploadAttachment) => void;
    kiloByteLabel?: string | React.ReactNode;
    megaByteLabel?: string | React.ReactNode;
    onDeleteLabel?: (attachment: IFileUploadAttachment) => string | React.ReactNode;
    onDownloadLabel?: (attachment: IFileUploadAttachment) => string | React.ReactNode;
    onDownloadInProgressLabel?: (attachment: IFileUploadAttachment) => string | React.ReactNode;
    onDownloadFile: (attachment: IFileUploadAttachment) => Promise<File>;
    hideSize?: boolean;
    hasErrors?: boolean;
}

export const DropAreaPreview = ({
    attachments,
    onDelete,
    onDownloadFile,
    kiloByteLabel = 'Kb',
    megaByteLabel = 'Mb',
    onDeleteLabel,
    onDownloadLabel,
    onDownloadInProgressLabel,
    hideSize = false,
    hasErrors = false,
}: IDropAreaPreviewProps) => {
    if (!attachments || attachments?.length === 0) {
        return null;
    }

    return (
        <div
            className="drop-area-preview drop-area-preview-alt drop-area-preview-list px-12 px-md-24"
            aria-invalid={hasErrors}
            role="list"
            style={{ border: hasErrors ? '1px solid var(--primary-red)' : '' }}
        >
            {attachments?.map((attachment) => (
                <FilePreview
                    attachment={attachment}
                    key={attachment.canonicalName}
                    onDelete={() => onDelete(attachment)}
                    kiloByteLabel={kiloByteLabel}
                    megaByteLabel={megaByteLabel}
                    deleteLabel={onDeleteLabel && onDeleteLabel(attachment)}
                    onDownloadFile={() => onDownloadFile(attachment)}
                    downloadLabel={onDownloadLabel && onDownloadLabel(attachment)}
                    downloadInProgressLabel={onDownloadInProgressLabel && onDownloadInProgressLabel(attachment)}
                    hideSize={hideSize}
                />
            ))}
        </div>
    );
};

interface IFilePreviewProps {
    attachment: IFileUploadAttachment;
    onDelete: (attachment: IFileUploadAttachment) => void;
    kiloByteLabel?: string | React.ReactNode;
    megaByteLabel?: string | React.ReactNode;
    deleteLabel?: string | React.ReactNode;
    downloadLabel?: string | React.ReactNode;
    downloadInProgressLabel?: string | React.ReactNode;
    onDownloadFile?: () => Promise<File>;
    hideSize?: boolean;
}

const FilePreview = ({
    attachment,
    onDelete,
    kiloByteLabel,
    megaByteLabel,
    deleteLabel,
    downloadLabel,
    downloadInProgressLabel,
    onDownloadFile,
    hideSize,
}: IFilePreviewProps) => {
    const elementId = getElementId(null);
    const deleteAriaElementId = `file-preview-delete-link-aria-${elementId}`;
    const downloadElementId = `file-preview-download-link-aria-${elementId}`;

    const [download, isLoading, fileSize] = useFilePreview({ onDownloadFile, attachment });

    const formatSize = (size: number): React.ReactNode => {
        const kiloBytes = size / 1024;

        if (kiloBytes > 1024) {
            return (
                <>
                    {`${(size / (1024 * 1024)).toFixed(1)}`} {megaByteLabel}
                </>
            );
        }
        return (
            <>
                {`${kiloBytes.toFixed(1)}`} {kiloByteLabel}
            </>
        );
    };

    return (
        <div className="preview-item w-100 py-6 py-md-12" role="listitem">
            <div className="preview-link d-flex align-items-center">
                <div className="sr-only" id={downloadElementId}>
                    {isLoading ? downloadInProgressLabel : downloadLabel}
                </div>
                {/* eslint-disable-next-line */}
                <a
                    onClick={download}
                    className={clsx('text-truncate', { disabled: isLoading })}
                    aria-labelledby={downloadElementId}
                    title={attachment.displayName}
                >
                    <span className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-download"></span>
                    {attachment.displayName}
                </a>
                {!hideSize && <span className="file-size px-8">({formatSize(fileSize())})</span>}
            </div>

            <button className="btn-link" type="button" onClick={() => onDelete(attachment)} aria-labelledby={deleteAriaElementId}>
                <span className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-delete" />
            </button>
            <label className="sr-only" id={deleteAriaElementId}>
                {deleteLabel}
            </label>
        </div>
    );
};
