import * as React from 'react';
import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';

export type AlertType = 'success' | 'info' | 'warning' | 'danger';

export type IAlertProps = {
    type: AlertType;
    spacing?: 'sm';
    dismissible?: boolean;
    closeButtonAriaLabel?: string | React.ReactNode;
    onClose?: () => void;
};

export const Alert: React.FC<PropsWithChildren<IAlertProps> & React.HTMLProps<HTMLDivElement>> = ({
    className,
    type,
    spacing,
    dismissible,
    closeButtonAriaLabel = '',
    onClose,
    children,
    ...others
}) => {
    return (
        <div role="alert" className={clsx(className, 'alert', `alert-${type}`, spacing && `alert-${spacing}`)} {...others}>
            {dismissible && (
                <>
                    <button type="button" className="close" aria-labelledby="vud-alert__close-button__label" onClick={onClose} />
                    <div id="vud-alert__close-button__label" className="sr-only">
                        {closeButtonAriaLabel}
                    </div>
                </>
            )}
            {children}
        </div>
    );
};
