import { useState, useEffect } from 'react';

// Use interface instead of type
interface KatexType {
    renderToString: (tex: string, options?: any) => string;
}

export const useKatex = (): KatexType | null => {
    const [katex, setKatex] = useState<KatexType | null>(null);

    useEffect(() => {
        const loadKatex = async (): Promise<void> => {
            try {
                // Dynamic import of KaTeX
                const katexPromise = import('katex');
                const cssPromise = import('katex/dist/katex.css');

                const [katexModule] = await Promise.all([katexPromise, cssPromise]);

                setKatex(katexModule.default);
            } catch (error) {
                console.error('Could not load KaTeX library:', error);
            }
        };

        // Handle promise with void operator to prevent floating promise warning
        void loadKatex();
    }, []);

    return katex;
};
