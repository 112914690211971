import * as React from 'react';
import { clsx } from 'clsx';

export interface ICardsListItemDescriptionProps {
    className?: string;
}

export const CardsListItemDescription: React.FC<React.PropsWithChildren<ICardsListItemDescriptionProps>> = ({ children, className }) => {
    return <div className={clsx(className, 'description')}>{children}</div>;
};
