import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';
import { ReactElement } from 'react';

export type StepType = 'active' | 'passed';

export interface IStepperStepProps {
    stepTitle: ReactElement | string;
    stepLabel?: ReactElement | string;
    stepType?: StepType;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const StepperStep: React.FC<IStepperStepProps> = ({
    stepTitle,
    stepLabel = '',
    stepType,
    className,
    onClick,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className || '');

    classBuilder.add(stepType, isDefined(stepType));

    return (
        <li className={classBuilder.build()} {...others}>
            <a
                tabIndex={0}
                role="link"
                rel="noopener noreferrer"
                onClick={(event) => {
                    if (onClick) {
                        onClick(event);
                    }
                }}
            >
                <span>{stepTitle}</span>
                <span className="step">{stepLabel}</span>
            </a>
        </li>
    );
};
