import * as React from 'react';
import { ComboboxItemDisplayCaseInsensitive } from './ComboboxItemDisplayCaseInsensitive';
import { useCombobox } from './hooks/useCombobox';
import { Combobox } from './Combobox';
import { ComboboxWrapper } from './ComboboxWrapper';
import { ComboboxButton } from './ComboboxButton';
import { ComboboxList } from './ComboboxList';
import { ComboboxDropdown } from './ComboboxDropdown';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxClearIcon } from './ComboboxClearIcon';
import { ComboboxItem } from './ComboboxItem';
import { IComboboxItemOptionProps } from './models/IComboboxItemOptionProps';
import { usePopper } from 'react-popper';

export interface IComboboxSimpleProps {
    onInputChange: (value: string) => void;
    options: IComboboxItemOptionProps[];
    className?: string;
    name?: string;
    value?: string;
    hasError?: boolean;
    placeholder?: string;
    dropdownAriaLabel?: string;
    clearAriaLabel?: string;
    noSuggestionLabel?: string;
    listHeader?: string | React.ReactNode;
    listAriaLabel?: string;
    defaultText?: string;
}

export const ComboboxSimple: React.FC<IComboboxSimpleProps> & React.HTMLProps<HTMLInputElement> = ({
    placeholder = '',
    options,
    noSuggestionLabel,
    listHeader,
    clearAriaLabel = 'Delete selection in input',
    dropdownAriaLabel = 'Expand dropdown',
    listAriaLabel = 'Choose from list',
    onInputChange,
    defaultText,
    className = '',
    hasError = false,
}) => {
    const [inputItems, setInputItems] = React.useState(options);

    const [
        inputText,
        getInputProps,
        getClearIconProps,
        getButtonProps,
        getDropDownProps,
        getListProps,
        getComboboxProps,
        getItemProps,
        getWrapperProps,
    ] = useCombobox({
        options: inputItems,
        onChange: (text: string) => {
            setInputItems(options?.filter((item) => item.text.toLowerCase().includes(text?.toLowerCase())));
            onInputChange(text);
        },
        defaultText,
    });

    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'absolute',
        placement: 'bottom-end',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 1],
                },
            },
        ],
    });

    return (
        <>
            <ComboboxWrapper {...getWrapperProps()} className={className}>
                <Combobox {...getComboboxProps()} hasError={hasError} ref={setReferenceElement}>
                    <ComboboxInput {...getInputProps()} placeholder={placeholder} />
                </Combobox>
                <ComboboxClearIcon {...getClearIconProps()} aria-label={clearAriaLabel} />
                <ComboboxButton {...getButtonProps()} aria-label={dropdownAriaLabel} />
                <ComboboxDropdown
                    {...getDropDownProps()}
                    header={listHeader}
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <ComboboxList {...getListProps()} noSuggestionLabel={noSuggestionLabel} aria-label={listAriaLabel}>
                        {inputItems?.length > 0 &&
                            inputItems.map((item) => {
                                return (
                                    <ComboboxItem {...getItemProps(item)} key={item.key}>
                                        <ComboboxItemDisplayCaseInsensitive filter={inputText} value={item.text} />
                                    </ComboboxItem>
                                );
                            })}
                    </ComboboxList>
                </ComboboxDropdown>
            </ComboboxWrapper>
        </>
    );
};
