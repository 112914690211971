import * as React from 'react';

export interface IComboboxItemDisplayCaseInsensitive {
    value: string;
    filter: string;
}

export const ComboboxItemDisplayCaseInsensitive = ({ value, filter }: IComboboxItemDisplayCaseInsensitive) => {
    if (filter === '') {
        return <>{value}</>;
    }

    const escapeRegexpFilter = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = value.split(new RegExp(`(${escapeRegexpFilter})`, 'gi'));

    return (
        <div>
            {parts.map((part, index) => {
                if (part.toLocaleLowerCase() === filter.toLocaleLowerCase()) {
                    return <mark key={`${value}-${part}-${index}`}>{part}</mark>;
                }

                return part;
            })}
        </div>
    );
};
