import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { SearchInfoRegular } from '@fluentui/react-icons';
import { t } from 'i18next';
import { customTokens } from '../../styles';

const useClasses = makeStyles({
    askButton: {
        textAlign: 'left',
        ...shorthands.margin('4px'),
        ...shorthands.padding('8px'),
        userSelect: 'none',
        cursor: 'pointer',
        backgroundColor: tokens.colorNeutralBackground1,
        color: customTokens.spcsNeutral90,
        borderRadius: tokens.borderRadiusXLarge,
        '&selectors': {
            '&:empty': {
                visibility: 'hidden',
            },
            '@media(max-width: 899px)': {},
        },
        ':hover': {
            backgroundColor: '#EEE7F9',
            color: '#252626',
        },
        maxWidth: '100%',
    },
    askButtonContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'left',
        width: '100%',
        flexDirection: 'column',
        flexWrap: 'wrap',
        '&selectors': {
            '@media(max-width: 899px)': {
                flexDirection: 'column',
            },
        },
    },
    askButtonContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '64px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: tokens.fontWeightSemibold,
        color: customTokens.spcsNeutral90,
    },
    searchIcon: {
        fontSize: tokens.fontSizeHero800,
    },
});

interface Props {
    items?: string[];
    onClick: (text: string) => void;
}

export const ChatSupplementaryQuestions: React.FC<Props> = ({ items, onClick }) => {
    const classes = useClasses();
    if (items === undefined || items.length === 0) {
        return null;
    }
    return (
        <div className={classes.askButtonContent}>
            <div className={classes.header}>
                <SearchInfoRegular className={classes.searchIcon} />
                {t('chatSupplementaryQuestions.suggestions')}
            </div>
            <div className={`${classes.askButtonContainer} `}>
                {items.map((text, index) => (
                    <div
                        key={index}
                        className={classes.askButton}
                        onClick={() => {
                            onClick(text);
                        }}
                    >
                        {text}
                    </div>
                ))}
            </div>
        </div>
    );
};
