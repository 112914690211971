import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { getElementId } from '../utils/getElementId';
import { isDefined } from '../utils/isDefined';

export type SearchType = 'lg' | 'primary' | 'button';

export interface ISearchProps {
    id?: string;
    label?: string | React.ReactElement;
    ariaLabelClearSearch?: string | React.ReactElement;
    buttonText?: string | React.ReactElement;
    className?: string;
    placeholder?: string;
    searchType?: SearchType;
    disabled?: boolean;
    onSearch?: (text: string) => void;
    onInputChange: (text: string) => void;
}

export const Search: React.FC<React.PropsWithChildren<ISearchProps>> & React.HTMLProps<HTMLInputElement> = ({
    id,
    label,
    ariaLabelClearSearch,
    buttonText,
    className,
    placeholder,
    searchType,
    disabled = false,
    onSearch,
    onInputChange,
}) => {
    const [searchText, setSearchText] = React.useState('');

    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('form-group').add(`search-group`).add(`search-group-${searchType}`, isDefined(searchType));

    const elementId = getElementId(id);

    const isButtonType = searchType === 'button';

    return (
        <div role="search" className={classBuilder.build()}>
            <label htmlFor={elementId} className="sr-only">
                {label}
            </label>
            <input
                id={elementId}
                className="form-control"
                autoComplete="off"
                type="search"
                value={searchText}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(event) => {
                    const inputValue = event.target.value;
                    setSearchText(inputValue);
                    onInputChange(inputValue);
                }}
            />
            {isButtonType && (
                <button className="btn search-icon" type="submit" tabIndex={0} onClick={() => onSearch && onSearch(searchText)}>
                    {buttonText}
                </button>
            )}
            {!isButtonType && <span className="search-icon" />}
            <span aria-labelledby={`clear-search-label-${elementId}`} onClick={() => setSearchText('')} className="clear-search" />
            <div id={`clear-search-label-${elementId}`} className="sr-only">
                {ariaLabelClearSearch}
            </div>
        </div>
    );
};
