import * as React from 'react';
import { clsx } from 'clsx';

export interface ICardsListItemTitleProps {
    className?: string;
    title?: string;
}

export const CardsListItemTitle: React.FC<React.PropsWithChildren<ICardsListItemTitleProps>> = ({ children, title, className }) => {
    return (
        <div className={clsx(className, `title col-md-3`)}>
            <span className="vismaicon vismaicon-time"></span>
            <div>
                <h4>{title}</h4>
                {children}
            </div>
        </div>
    );
};
