import * as React from 'react';
import { forwardRef, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

export interface IComboboxDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    expanded: boolean;
    className?: string;
    header?: string | React.ReactNode;
}

export const ComboboxDropdown = forwardRef<HTMLDivElement, PropsWithChildren<IComboboxDropdownProps>>(function ComboboxDropdown(
    { header, expanded, className = '', children, ...rest },
    ref,
) {
    if (!expanded) {
        return null;
    }

    return (
        <div ref={ref} className={clsx(className, 'combobox-dropdown show')} {...rest}>
            {header && <div className="combobox-dropdown-header">{header}</div>}
            {children}
        </div>
    );
});
