import { Link } from '@fluentui/react-components';
import React, { AnchorHTMLAttributes, FC, useCallback, useMemo, useEffect, useRef, MouseEventHandler } from 'react';
import { Attachment, IChatMessage } from '../../libs/models/ChatMessage';
import { useAttachment } from '../../contexts/AttachmentContext';
import { t } from 'i18next';
import { Button } from '@vismaux/react-vud';

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string;
    children?: React.ReactNode;
    message: IChatMessage;
    isLastMessage: boolean;
}

const getAttachment = (children: React.ReactNode, message: IChatMessage): Attachment | null => {
    if (typeof children === 'string' && children.startsWith('attachment')) {
        const attachmentIndex = parseInt(children.split(':')[1], 10);
        return message.attachments?.[attachmentIndex] ?? null;
    }
    return null;
};

const CustomLink: FC<CustomLinkProps> = React.memo(
    ({ href, children, message, isLastMessage, ...props }) => {
        const { setActiveAttachment, activeAttachment } = useAttachment();
        const attachment = useMemo(() => getAttachment(children, message), [children, message]);
        const hasBeenShown = useRef(false);

        const handleClick = useCallback<MouseEventHandler<HTMLElement>>(() => {
            hasBeenShown.current = true;
            if (activeAttachment.attachment === attachment) {
                setActiveAttachment(null, 'closed');
            } else {
                setActiveAttachment(attachment, message);
            }
        }, [attachment, message, setActiveAttachment, activeAttachment.attachment]);

        useEffect(() => {
            if (isLastMessage && attachment && !hasBeenShown.current) {
                setActiveAttachment(attachment, message);
                hasBeenShown.current = true;
            }
        }, [attachment, isLastMessage, message, setActiveAttachment]);

        const renderAsLinkButton = useCallback(
            () => (
                <div>
                    <Button onClick={handleClick}>
                        {activeAttachment.attachment === attachment
                            ? t('attachments.closeAttachment')
                            : t('attachments.openAttachment')}
                    </Button>
                </div>
            ),
            [handleClick, attachment, activeAttachment.attachment],
        );

        const renderAttachment = useCallback(() => {
            if (!attachment) {
                return (
                    <Link href={href} target="_blank" rel="noopener noreferrer" {...props}>
                        {children}
                    </Link>
                );
            }

            switch (attachment.attachmentType) {
                case 'table':
                case 'chart':
                    return renderAsLinkButton();
                default:
                    return (
                        <Link href={href} {...props}>
                            {children}
                        </Link>
                    );
            }
        }, [attachment, href, props, children, renderAsLinkButton]);

        return renderAttachment();
    },
    (prevProps, nextProps) => {
        return (
            prevProps.href === nextProps.href &&
            prevProps.children === nextProps.children &&
            prevProps.message === nextProps.message &&
            prevProps.isLastMessage === nextProps.isLastMessage &&
            JSON.stringify(prevProps.message.attachments) === JSON.stringify(nextProps.message.attachments)
        );
    },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
