import { useState } from 'react';

export const useDrawer = () => {
    const [open, setOpen] = useState(false);
    const [className, setClassName] = useState('drawer');
    const toggleDrawer = () => {
        setOpen(!open);
        setClassName(open ? 'drawer' : 'drawer show');
    };
    const buttonProps = {
        onClick: toggleDrawer,
    };

    const containerProps = {
        className,
        onClick: toggleDrawer,
    };

    return {
        open,
        toggleDrawer,
        buttonProps,
        containerProps,
    };
};
