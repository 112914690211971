import * as React from 'react';
import { ComponentProps, forwardRef, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

export type InputFieldType = 'text' | 'password' | 'email' | 'number';

export interface IInputFieldProps {
    onChange: (value: string) => void;
    type?: InputFieldType;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    hasError?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, PropsWithChildren<IInputFieldProps> & ComponentProps<'input'>>(
    ({ className, type, disabled, placeholder, value, hasError = false, onChange, ...others }, ref) => {
        return (
            <input
                ref={ref}
                className={clsx(className, 'input', type, { 'has-error': hasError })}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                {...others}
            />
        );
    },
);
