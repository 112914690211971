import * as React from 'react';
import { ChangeEvent, forwardRef, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { ScreenSize } from '../../utils/enums/ScreenSize';
import { getElementId } from '../../utils/getElementId';
import { clsx } from 'clsx';

type SwitchType = 'checkbox' | 'radio';
type SwitchSize = 'lg';

export interface ISwitchProps {
    onChange: (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
    type?: SwitchType;
    value?: string | number;
    id?: string;
    className?: string;
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    label?: string | ReactElement;
    asLabel?: boolean;
    size?: SwitchSize;
}

export const Switch = forwardRef<HTMLInputElement, PropsWithChildren<ISwitchProps>>(
    (
        { id, className = '', value, asLabel = true, size = '', type = 'checkbox', name, label, checked = false, onChange, ...otherProps },
        ref,
    ) => {
        const elementId = getElementId(id);
        const [isChecked, setIsChecked] = useState(checked);

        useEffect(() => {
            setIsChecked(checked);
        }, [checked]);

        return (
            <div className={clsx(className, 'switch', { 'switch-lg': size === ScreenSize.lg, 'switch-label': asLabel })}>
                <input
                    ref={ref}
                    id={elementId}
                    type={type}
                    name={name}
                    value={value}
                    checked={isChecked}
                    onChange={(event) => {
                        setIsChecked(!isChecked);
                        onChange(event, event.target.checked);
                    }}
                    {...otherProps}
                />
                <label htmlFor={elementId} className="togglemark">
                    {label}
                </label>
            </div>
        );
    },
);
