import React, { useState, useEffect } from 'react';
import { useKatex } from '../../hooks/useKatex';

interface AiaLatexProps {
    content: string;
}

export const AiaLatex: React.FC<AiaLatexProps> = ({ content }) => {
    const katex = useKatex();
    const [renderedHtml, setRenderedHtml] = useState<string>(content);

    useEffect(() => {
        if (!katex) {
            return;
        }

        try {
            // Prepare content for LaTeX rendering
            const preparedContent = prepareForDisplay(content, katex);
            setRenderedHtml(preparedContent);
        } catch (error) {
            console.error('Error rendering LaTeX:', error);
            setRenderedHtml(content);
        }
    }, [content, katex]);

    return <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />;
};

// Helper function to detect LaTeX
export const containsLatex = (text: string): boolean => {
    // Search for LaTeX syntax
    return /\\frac|\\\times|\\div|\\sum|\\prod|\\int|\\\[|\\\(|\$\$|\$/i.test(text);
};

// Prepare text for display, render LaTeX if it exists
const prepareForDisplay = (text: string, katex: any): string => {
    // Identify common LaTeX expressions
    const latexRegex =
        /(\\frac\{[^{}]+\}\{[^{}]+\}|\\sqrt(\[[^\]]*\])?\{[^{}]+\}|\\(sum|prod|int)(_\{[^{}]+\})?\^?\{?[^{}]*\}?|\\(alpha|beta|gamma|delta|theta|lambda|mu|pi|sigma|omega)|\\(times|div|cdot|pm|leq|geq|neq|approx|equiv|infty)|[^$]*=\s*\d+\s*kr)/g;

    // Replace commas with dots in LaTeX expressions
    const fixedText = text.replace(/(\d),(\d)/g, '$1.$2');

    // Helper function to safely render LaTeX
    const safeRender = (tex: string): string => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            if (katex && typeof katex.renderToString === 'function') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                const result = katex.renderToString(tex, {
                    throwOnError: false,
                    displayMode: false,
                });

                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return String(result);
            }
        } catch (error) {
            console.error('LaTeX rendering failed:', error);
        }
        return tex;
    };

    // Replace LaTeX expressions with rendered versions
    return fixedText.replace(latexRegex, safeRender);
};
