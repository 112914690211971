import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductConstants } from '../../constants/ProductConstants';
import { useChat } from '../../libs/hooks';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setHostContext } from '../../redux/features/hostContext/hostContextSlice';
import RadioGroup from './RadioGroup';
import { Checkbox, Button as VudButton } from '@vismaux/react-vud';

const SWEDEN = 'SE';
const NETHERLANDS = 'NL';

const AVAILABLE_PRODUCTS: Record<string, { products: string[]; defaultVariants: Record<string, string> }> = {
    [SWEDEN]: {
        products: [
            ProductConstants.EAccounting.ProductName,
            ProductConstants.Advisor.ProductName,
            ProductConstants.CloudPayroll.ProductName,
            ProductConstants.Speedledger.ProductName,
            ProductConstants.ApyVpyETax.ProductNameAPY,
            ProductConstants.ApyVpyETax.ProductNameETax_SE_AB,
            ProductConstants.ApyVpyETax.ProductNameETax_SE_EF,
            ProductConstants.VismaKoncern.ProductName,
            ProductConstants.VismaAdminitration.ProductName,
            ProductConstants.VismaCompact.ProductName,
            ProductConstants.VismaLon.ProductName,
            ProductConstants.VismaEnskildFirma.ProductName,
            ProductConstants.VismaTid.ProductName,
        ],
        defaultVariants: {
            [ProductConstants.EAccounting.ProductName]: ProductConstants.EAccounting.Variants.pro,
            [ProductConstants.Advisor.ProductName]: ProductConstants.Advisor.Variants.standard,
            [ProductConstants.CloudPayroll.ProductName]: ProductConstants.CloudPayroll.Variants.smart,
            [ProductConstants.VismaAdminitration.ProductName]:
                ProductConstants.VismaAdminitration.Variants.administration2000,
            [ProductConstants.VismaCompact.ProductName]: ProductConstants.VismaCompact.Variants.compact3000,
            [ProductConstants.VismaLon.ProductName]: ProductConstants.VismaLon.Variants.vismalon600,
        },
    },
    [NETHERLANDS]: {
        products: [ProductConstants.EAccounting.ProductName],
        defaultVariants: {
            [ProductConstants.EAccounting.ProductName]: ProductConstants.EAccounting.Variants.pro,
        },
    },
};

const useClasses = makeStyles({
    radio: {
        display: 'inline-block',
    },
});

export const HostContextSelector: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hostContext = useAppSelector((state: RootState) => state.hostContext.hostContext);
    const currentChatSession = useAppSelector(
        (state: RootState) => state.conversations.conversations[state.conversations.selectedId],
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const chat = useChat();
    const classes = useClasses();

    const handleOpenChange = (_: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        const wasOpen = isOpen;
        setIsOpen(!isOpen);
        if (wasOpen) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (currentChatSession && currentChatSession.hostContext !== hostContext) {
                chat.createChat(hostContext)
                    .catch(() => {})
                    .finally(() => {});
                localStorage.setItem('hostContext', JSON.stringify(hostContext));
            }
        }
    };

    const handleProductChange = (productName: string, isChecked: boolean) => {
        let products = hostContext.products ?? [];
        let companyType = hostContext.companyType;

        if (isChecked) {
            const defaultVariant = AVAILABLE_PRODUCTS[hostContext.country ?? SWEDEN].defaultVariants[productName];
            const changedProduct: { name: string; variant?: string } = {
                name: productName,
                variant: defaultVariant,
            };
            switch (productName) {
                case ProductConstants.ApyVpyETax.ProductNameETax_SE_AB:
                    products = products.filter((p) => p.name !== ProductConstants.ApyVpyETax.ProductNameETax_SE_EF);
                    changedProduct.name = ProductConstants.ApyVpyETax.ProductNameETax_SE_AB;
                    companyType = 'Corporation';
                    break;
                case ProductConstants.ApyVpyETax.ProductNameETax_SE_EF:
                    products = products.filter((p) => p.name !== ProductConstants.ApyVpyETax.ProductNameETax_SE_AB);
                    changedProduct.name = ProductConstants.ApyVpyETax.ProductNameETax_SE_EF;
                    companyType = 'SoleProprietorship';
                    break;
                default:
                    break;
            }

            products = [...products, changedProduct];
        } else {
            products = products.filter((p) => p.name !== productName);
        }

        const newCtx = {
            ...hostContext,
            products,
            companyType,
        };

        dispatch(setHostContext(newCtx));
        localStorage.setItem('hostContext', JSON.stringify(newCtx));
    };

    const handleVariantChange = (productName: string, variant: string) => {
        const products = hostContext.products ?? [];
        const updatedProducts = products.map((product) =>
            product.name === productName ? { ...product, variant } : product,
        );
        const newCtx = { ...hostContext, products: updatedProducts };
        dispatch(setHostContext(newCtx));
        localStorage.setItem('hostContext', JSON.stringify(newCtx));
    };

    const renderProductCheckbox = (
        productName: string,
        label: string,
        variants?: Array<{ value: string; label: string }>,
    ) => {
        const products = hostContext.products ?? [];
        const isChecked = products.some((p) => p.name === productName);
        const product = products.find((p) => p.name === productName);

        return (
            <div>
                <Checkbox
                    onChange={(_, isChecked) => {
                        handleProductChange(productName, isChecked);
                    }}
                    checked={isChecked}
                    label={label}
                />
                {isChecked && variants && (
                    <RadioGroup
                        name={`${productName}Variant`}
                        options={variants}
                        selectedValue={product?.variant ?? ''}
                        onChange={(value) => {
                            handleVariantChange(productName, value);
                        }}
                    />
                )}
            </div>
        );
    };

    const renderCountryOptions = () => {
        return (
            <div>
                <div className={mergeClasses('radio', classes.radio)}>
                    <input
                        type="radio"
                        id={'country_SE'}
                        value={SWEDEN}
                        checked={hostContext.country === SWEDEN}
                        onChange={() => {
                            const products = AVAILABLE_PRODUCTS[SWEDEN];
                            const firstProduct = products.products[0];
                            const defaultVariant = products.defaultVariants[firstProduct];
                            dispatch(
                                setHostContext({
                                    ...hostContext,
                                    country: SWEDEN,
                                    products: [{ name: firstProduct, variant: defaultVariant }],
                                }),
                            );
                        }}
                    />
                    <label htmlFor={'country_SE'}>Sweden</label>
                </div>
                <div className={mergeClasses('radio', classes.radio)}>
                    <input
                        type="radio"
                        id={'country_NL'}
                        value={NETHERLANDS}
                        checked={hostContext.country === NETHERLANDS}
                        onChange={() => {
                            const products = AVAILABLE_PRODUCTS[NETHERLANDS];
                            const firstProduct = products.products[0];
                            const defaultVariant = products.defaultVariants[firstProduct];
                            dispatch(
                                setHostContext({
                                    ...hostContext,
                                    country: NETHERLANDS,
                                    products: [{ name: firstProduct, variant: defaultVariant }],
                                }),
                            );
                        }}
                    />
                    <label htmlFor={'country_NL'}>Netherlands</label>
                </div>
            </div>
        );
    };

    const renderProductOptions = () => {
        const availableProducts = (AVAILABLE_PRODUCTS[hostContext.country ?? SWEDEN] ?? AVAILABLE_PRODUCTS[SWEDEN])
            .products;

        return (
            <>
                {availableProducts.includes(ProductConstants.EAccounting.ProductName) &&
                    renderProductCheckbox(ProductConstants.EAccounting.ProductName, 'eEkonomi', [
                        { value: ProductConstants.EAccounting.Variants.bookkeeping, label: 'Bokföring' },
                        { value: ProductConstants.EAccounting.Variants.invoicing, label: 'Fakturering' },
                        { value: ProductConstants.EAccounting.Variants.pro, label: 'Pro' },
                        { value: ProductConstants.EAccounting.Variants.solo, label: 'Solo' },
                        { value: ProductConstants.EAccounting.Variants.standard, label: 'Smart' },
                    ])}
                {availableProducts.includes(ProductConstants.Advisor.ProductName) &&
                    renderProductCheckbox(ProductConstants.Advisor.ProductName, 'Advisor', [
                        { value: ProductConstants.Advisor.Variants.standard, label: 'Standard' },
                        { value: ProductConstants.Advisor.Variants.start, label: 'Start' },
                        { value: ProductConstants.Advisor.Variants.connect, label: 'Connect' },
                    ])}
                {availableProducts.includes(ProductConstants.CloudPayroll.ProductName) &&
                    renderProductCheckbox(ProductConstants.CloudPayroll.ProductName, 'Lön', [
                        { value: 'smart', label: 'Smart' },
                        { value: 'direct', label: 'Direct' },
                    ])}
                {availableProducts.includes(ProductConstants.Speedledger.ProductName) &&
                    renderProductCheckbox(ProductConstants.Speedledger.ProductName, 'Speedledger')}
                {availableProducts.includes(ProductConstants.ApyVpyETax.ProductNameAPY) &&
                    renderProductCheckbox(ProductConstants.ApyVpyETax.ProductNameAPY, 'Visma Skatt & Bokslut Pro')}
                {availableProducts.includes(ProductConstants.ApyVpyETax.ProductNameETax_SE_AB) &&
                    renderProductCheckbox(
                        ProductConstants.ApyVpyETax.ProductNameETax_SE_AB,
                        'Visma Deklaration Årsredovisning',
                    )}
                {availableProducts.includes(ProductConstants.ApyVpyETax.ProductNameETax_SE_EF) &&
                    renderProductCheckbox(
                        ProductConstants.ApyVpyETax.ProductNameETax_SE_EF,
                        'Visma Deklaration Årsbokslut',
                    )}
                {availableProducts.includes(ProductConstants.VismaKoncern.ProductName) &&
                    renderProductCheckbox(ProductConstants.VismaKoncern.ProductName, 'Visma Koncern')}
                {availableProducts.includes(ProductConstants.VismaAdminitration.ProductName) &&
                    renderProductCheckbox(ProductConstants.VismaAdminitration.ProductName, 'Visma Administration', [
                        { value: 'administration500', label: '500' },
                        { value: 'administration1000', label: '1000' },
                        { value: 'administration2000', label: '2000' },
                    ])}
                {availableProducts.includes(ProductConstants.VismaCompact.ProductName) &&
                    renderProductCheckbox(ProductConstants.VismaCompact.ProductName, 'Visma Compact', [
                        { value: 'compact500', label: '500' },
                        { value: 'compact750', label: '750' },
                        { value: 'compact1000', label: '1000' },
                        { value: 'compact1500', label: '1500' },
                        { value: 'compact3000', label: '3000' },
                    ])}
                {availableProducts.includes(ProductConstants.VismaLon.ProductName) &&
                    renderProductCheckbox(ProductConstants.VismaLon.ProductName, 'Visma Lön', [
                        { value: 'vismalon300', label: '300' },
                        { value: 'vismalon600', label: '600' },
                    ])}
                {availableProducts.includes(ProductConstants.VismaEnskildFirma.ProductName) &&
                    renderProductCheckbox(ProductConstants.VismaEnskildFirma.ProductName, 'Visma Enskild firma')}
                {availableProducts.includes(ProductConstants.VismaTid.ProductName) &&
                    renderProductCheckbox(ProductConstants.VismaTid.ProductName, 'Visma Tid')}
            </>
        );
    };

    return (
        <Dialog open={isOpen} onOpenChange={handleOpenChange}>
            <DialogTrigger>
                <Button style={{ borderRadius: 100, backgroundColor: '#48237d', color: 'white', marginRight: '1em' }}>
                    {t('hostContext.triggerButton')}
                </Button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{t('hostContext.dialog.title')}</DialogTitle>
                    <DialogContent>
                        {renderCountryOptions()}
                        {renderProductOptions()}
                    </DialogContent>
                    <DialogActions>
                        <VudButton buttonType={'primary'} onClick={handleOpenChange}>
                            {t('hostContext.dialog.submitButton')}
                        </VudButton>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
