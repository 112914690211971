import * as React from 'react';
import { IFileUploadAttachment } from '../models/IFileUploadAttachment';

export function useFilePreview(props: { onDownloadFile?: () => Promise<File>; attachment: IFileUploadAttachment }) {
    const [loadedFile, setLoadedFile] = React.useState<File>();
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setFileAttributes(props.attachment?.file);
    }, [props.attachment]);

    const setFileAttributes = (file: File) => {
        if (file) {
            setLoadedFile(file);
        }
    };

    const download = async () => {
        try {
            setIsLoading(true);

            const file = loadedFile ?? (props.onDownloadFile && (await props.onDownloadFile()));
            if (file) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(file);
                link.setAttribute('download', props.attachment?.displayName);
                document.body.appendChild(link);

                link.click();

                URL.revokeObjectURL(link.href);
                link.parentNode.removeChild(link);

                setFileAttributes(file);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fileSize = (): number => {
        return loadedFile?.size ?? props.attachment.size;
    };

    return [download, isLoading, fileSize] as const;
}
