import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';
import { getElementId } from '../utils/getElementId';

export type ProgressCircleSizeType = 'xs';

export interface IProgressCircleProps {
    className?: string;
    size?: ProgressCircleSizeType;
    percentage: number;
    title?: string;
    circleDescription?: string;
}

export const ProgressCircle: React.FC<IProgressCircleProps> & React.HTMLProps<HTMLDivElement> = ({
    className,
    size,
    percentage,
    title,
    circleDescription,
}) => {
    const elementId = getElementId(null);
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('progress-circle').add(`progress-circle-${size}`, isDefined(size));

    const titleId = `${elementId}-title`;
    const descriptionId = `${elementId}-description`;
    const fillCircle = 190 - (190 * percentage) / 100;

    return (
        <div className={classBuilder.build()}>
            <svg className="progress_svg" role="img" aria-labelledby={`${titleId} ${descriptionId}`}>
                <title id={titleId}>{title}</title>
                <desc id={descriptionId}>{circleDescription}</desc>
                <circle cx="30" cy="30" r="30" className="full-circle" />
                <circle cx="30" cy="30" r="30" className="progress-bar" style={{ strokeDashoffset: fillCircle }} />
            </svg>
            <span className="percentage">{percentage}%</span>
        </div>
    );
};
