import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export interface IStepperProps {
    vertical?: boolean;
    small?: boolean;
    transition?: boolean;
    className?: string;
}

export const Stepper: React.FC<React.PropsWithChildren<IStepperProps>> = ({
    vertical = false,
    small = false,
    className = '',
    transition = false,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder('stepper');

    classBuilder.add(className, isDefined(className));
    classBuilder.add('stepper-vertical', isDefined(vertical));
    classBuilder.add('transition', isDefined(transition));

    classBuilder.add('stepper-sm', isDefined(small) && !isDefined(vertical));
    classBuilder.add('stepper-vertical-sm', isDefined(small) && isDefined(vertical));

    return (
        <div className={classBuilder.build()} {...others}>
            <ul>{children}</ul>
        </div>
    );
};
