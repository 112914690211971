import * as React from 'react';
import { Icon } from '../Icons';
import { clsx } from 'clsx';
import { DropzoneSize } from './interfaces';
import { Spinner } from '../Spinner';

export interface IDropAreaProps {
    onUpload: (files: File[]) => void;
    dropFilesLabel?: string | React.ReactNode;
    size?: DropzoneSize;
    className?: string;
    hintText?: string | React.ReactNode;
    customBody?: React.ReactNode;
    showSpinner?: boolean;
}

export const DropArea = ({
    onUpload,
    dropFilesLabel = '',
    className = '',
    size = 'small',
    hintText,
    customBody,
    showSpinner = false,
}: IDropAreaProps) => {
    const [showHighlight, setShowHighlight] = React.useState(false);

    const onDropAreaEvent = (event: React.DragEvent, highlighted: boolean) => {
        event.preventDefault();
        setShowHighlight(highlighted);
    };

    return (
        <div
            className={clsx('drop-area', {
                'drop-area-sm': size === 'small',
                highlight: showHighlight,
                [className]: !!className,
            })}
            onDragEnter={(event) => onDropAreaEvent(event, true)}
            onDragOver={(event) => onDropAreaEvent(event, true)}
            onDragLeave={(event) => onDropAreaEvent(event, false)}
            onDrop={(event) => {
                onDropAreaEvent(event, false);
                onUpload(Array.from(event.dataTransfer.files));
            }}
        >
            <label className="drop-area-upload" tabIndex={0}>
                <input
                    type="file"
                    multiple
                    className="drop-area-input"
                    aria-labelledby="dropAreaLabel"
                    onChange={(event) => {
                        onUpload(Array.from(event.target.files));
                        event.target.value = null;
                    }}
                />
                <Icon size="sm" name="download" aria-hidden={true} dynamic={true} />
                <span className="sr-only" id="dropAreaLabel">
                    {dropFilesLabel}
                </span>
            </label>
            {size === 'large' && !!hintText && <p>{hintText}</p>}
            {customBody}

            {showSpinner && <Spinner type="secondary" color="blue" className="show" />}
        </div>
    );
};
