import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type SpinnerType = 'default' | 'primary' | 'secondary';
export type SpinnerColorType = 'blue' | 'green' | 'grey';
export type SpinnerSizeType = 'sm' | 'xs';

export interface ISpinnerProps {
    className?: string;
    type?: SpinnerType;
    color?: SpinnerColorType;
    size?: SpinnerSizeType;
}

export const Spinner = ({ className, type = 'default', color = 'blue', size }: ISpinnerProps) => {
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('spinner').add(`spinner-${type}-${color}`).add(`spinner-${size}`, isDefined(size));

    return <span className={classBuilder.build()} role="status" />;
};
