export class ClassNameBuilder {
    private classes: string[];

    constructor(className = '') {
        this.classes = className ? className.split(' ') : [];
    }

    add(className: string, resolver = true): ClassNameBuilder {
        if (resolver) {
            this.classes.push(className);
        }
        return this;
    }

    size(): number {
        return this.classes.length;
    }

    hasAny(): boolean {
        return this.classes.length > 0;
    }

    build(): string {
        return this.classes.join(' ').trim();
    }
}
