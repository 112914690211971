import * as React from 'react';
import { clsx } from 'clsx';

export interface IComboboxListProps extends React.HTMLAttributes<HTMLUListElement> {
    expanded: boolean;
    noSuggestionLabel?: string | React.ReactNode;
    className?: string;
}

export const ComboboxList: React.FC<React.PropsWithChildren<IComboboxListProps>> = ({
    expanded,
    noSuggestionLabel,
    className = '',
    children,
    ...rest
}) => {
    return (
        <>
            <ul
                role="listbox"
                className={clsx(className, 'combobox-list z-index-10')}
                aria-multiselectable={false}
                aria-expanded={expanded}
                {...rest}
            >
                {children}
            </ul>
            {!children && noSuggestionLabel && <span className="combobox-info">{noSuggestionLabel}</span>}
        </>
    );
};
