import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';

interface IProps {
    variant?: 'default' | 'secondary';
    isMobile?: boolean;
    className?: string;
}

export const Navbar: React.FC<PropsWithChildren<IProps>> = ({ variant = 'default', isMobile = false, className, children, ...others }) => {
    const classBuilder = new ClassNameBuilder(className || '');

    classBuilder.add(`navbar navbar-${variant}`);
    classBuilder.add('is-mobile', isMobile);

    return (
        <>
            <header className={classBuilder.build()} {...others}>
                {children}
            </header>
        </>
    );
};
