import { FC } from 'react';
import { ReactComponent as AddMessageIcon } from '../../assets/bot-icons/add_message.svg';
import { ReactComponent as CancelIcon } from '../../assets/bot-icons/cancel.svg';
import { ReactComponent as PaperplaneIcon } from '../../assets/bot-icons/paperplane.svg';
import { ReactComponent as NewChatIcon } from '../../assets/bot-icons/new-chat-btn-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/bot-icons/user-icon.svg';
import { ReactComponent as SparklesIcon } from '../../assets/bot-icons/sparkles.svg';

const iconMap = {
    'add-message': AddMessageIcon,
    cancel: CancelIcon,
    paperplane: PaperplaneIcon,
    'new-chat': NewChatIcon,
    user: UserIcon,
    sparkles: SparklesIcon,
} as const;

interface IconProps extends React.SVGProps<SVGSVGElement> {
    name: keyof typeof iconMap;
    size?: number;
    color?: string;
    title?: string;
}

export const Icon: FC<IconProps> = ({ name, size = 24, color, style, title, ...props }) => {
    const IconComponent = iconMap[name];

    return (
        <IconComponent
            width={size}
            height={size}
            title={title}
            aria-label={title}
            style={{
                ...style,
                color: color,
            }}
            {...props}
        />
    );
};

export default Icon;
