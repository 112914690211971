import * as React from 'react';
import { clsx } from 'clsx';
import { HTMLAttributes } from 'react';

export interface IDrawersBodyProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
}

export const DrawersBody = React.forwardRef<HTMLDivElement, IDrawersBodyProps>(({ className, children, ...rest }, ref) => {
    return (
        <div ref={ref} className={clsx(className, 'drawer-body')} {...rest}>
            {children}
        </div>
    );
});
