import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type IconSize = 'sm' | 'lg';

export interface IIconProps {
    className?: string;
    name: string;
    dynamic?: boolean;
    size: IconSize;
    disabled?: boolean;
    children?: React.ReactNode;
}

export const Icon: React.FC<IIconProps> & React.HTMLProps<HTMLSpanElement> = ({
    className,
    name,
    dynamic,
    size,
    disabled,
    children,
    ...others
}) => {
    const iconShouldBeFilled = ['success', 'error', 'warning', 'info', 'help'].indexOf(name) > -1;
    const classBuilder = new ClassNameBuilder(className || '')
        .add('vismaicon')
        .add(`vismaicon-${name}`)
        .add('vismaicon-dynamic', isDefined(dynamic))
        .add(`vismaicon-${size}`)
        .add('disabled', isDefined(disabled))
        .add('vismaicon-filled', iconShouldBeFilled);

    return (
        <span className={classBuilder.build()} {...others}>
            {children}
        </span>
    );
};
