import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#48237D',
    20: '#502C82',
    30: '#593987',
    40: '#654691',
    50: '#674C8F',
    60: '#785AA3',
    70: '#7E64A3',
    80: '#876FA8',
    90: '#806D9C',
    100: '#806D9C',
    110: '#9B8AB5',
    120: '#AC9FBF',
    130: '#BEB2CF',
    140: '#CCC1DB',
    150: '#CCC1DB',
    160: '#E6DFF0',
};

interface CustomTokens {
    colorMeBackground: string;
    colorMeText: string;
    colorBotBackground: string;
    colorBotText: string;
    spcsNeutral90: string;
    spcsNeutral80: string;
    spcsNeutral70: string;
    spcsNeutral60: string;
    spcsNeutral50: string;
    spcsNeutral40: string;
    spcsNeutral30: string;
    spcsNeutral20: string;
    spcsNeutral10: string;
    spcsNeutral05: string;
    spcsPurple90: string;
    spcsPurple80: string;
    spcsPurple70: string;
    spcsPurple60: string;
    spcsPurple50: string;
    spcsPurple40: string;
    spcsPurple30: string;
    spcsPurple20: string;
    spcsPurple10: string;
    spcsPurple05: string;
    spcsBackgroundPrimaryLinear: string;
    colorDiscovery: string;
    colorDiscoveryHighlight: string;
}

export const semanticKernelLightTheme: Theme & CustomTokens = {
    ...createLightTheme(semanticKernelBrandRamp),
    spcsNeutral90: '#252626',
    spcsNeutral80: '#494a4a',
    spcsNeutral70: '#6b6b6b',
    spcsNeutral60: '#8a8a8a',
    spcsNeutral50: '#a5a5a5',
    spcsNeutral40: '#c0c0c0',
    spcsNeutral30: '#dadada',
    spcsNeutral20: '#f2f2f2',
    spcsNeutral10: '#f7f7f7',
    spcsNeutral05: '#ffffff',
    spcsPurple90: '#2f1b4c',
    spcsPurple80: '#48237d',
    spcsPurple70: '#6431ac',
    spcsPurple60: '#8049cc',
    spcsPurple50: '#9263d6',
    spcsPurple40: '#a57fdb',
    spcsPurple30: '#ba95f0',
    spcsPurple20: '#ceaffa',
    spcsPurple10: '#e3d2fa',
    spcsPurple05: '#eee7f9',
    spcsBackgroundPrimaryLinear: 'linear-gradient(to right, #48237d, #8049cc)',

    colorMeBackground: '#48237D',
    colorMeText: '#FFFFFF',
    colorBotBackground: 'transparent',
    colorBotText: '#252626',
    fontFamilyBase: 'Open Sans, sans-serif',
    fontFamilyMonospace: 'Open Sans, sans-serif',
    fontFamilyNumeric: 'Open Sans, sans-serif',
    colorDiscovery: '#4C4C4C',
    colorDiscoveryHighlight: '#000000',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#2b2b3e',
    fontFamilyBase: 'Open Sans, sans-serif',
    fontFamilyMonospace: 'Open Sans, sans-serif',
    fontFamilyNumeric: 'Open Sans, sans-serif',
};

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
        overscrollBehavior: 'contain',
    },
    overflowEllipsis: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('80px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
