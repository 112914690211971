import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import React, { useMemo } from 'react';
import { AuthorRoles, IChatMessage } from '../../../libs/models/ChatMessage';
import { ChatHistoryItem } from './ChatHistoryItem';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ChatStatusCard } from '../ChatStatusCard';
import { BotResponseStatus } from '../../../redux/features/conversations/ChatState';
import { ChatSupplementaryQuestions } from '../ChatSupplementaryQuestions';
import { DiscoveryQuestions } from './DiscoveryQuestions';
import { useDiscovery } from '../../../libs/hooks/useDiscovery';

const useClasses = makeStyles({
    root: {
        ...shorthands.gap(tokens.spacingVerticalM),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        width: '100%',
        justifySelf: 'center',
    },
    messageWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        ...shorthands.gap('1rem'),
        marginBottom: '1rem',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
    bot: {
        alignSelf: 'end',
        width: '100%',
    },
});

interface ChatHistoryProps {
    onQuerySubmit: (query: string) => void;
    onQuestionButtonClick: (text: string) => void;
    statuses: BotResponseStatus[];
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ onQuerySubmit, statuses, onQuestionButtonClick }) => {
    const classes = useClasses();
    const isBot = (message: IChatMessage) => message.authorRole === AuthorRoles.Bot;
    const { V2ExperimentalFeatures } = useAppSelector((state: RootState) => state.app.serviceInfo.featureFlags);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const messages = conversations[selectedId].messages;
    const hasStartedTyping = conversations[selectedId].input != '';
    const { isEnabled: discoveryEnabled } = useDiscovery();

    const handleQuestionButtonClick = (text: string) => {
        onQuestionButtonClick(text);
        onQuerySubmit(text);
    };

    const supplementaryItems = useMemo(() => {
        if (discoveryEnabled) {
            // For DynamicReport, show followUpAsks for all messages except the first one
            return messages.length > 1 ? messages[messages.length - 1].followUpAsks : [];
        }
        // For non-DynamicReport, keep the current behavior
        return messages.length > 0 ? messages[messages.length - 1].followUpAsks : [];
    }, [messages, discoveryEnabled]);

    return (
        <div className={classes.root}>
            {messages.map((message, index) => (
                <div
                    key={message.timestamp}
                    className={
                        isBot(message) ? mergeClasses(classes.messageWrapper, classes.bot) : classes.messageWrapper
                    }
                >
                    <ChatHistoryItem message={message} messageIndex={index} />
                </div>
            ))}
            <ChatSupplementaryQuestions onClick={handleQuestionButtonClick} items={supplementaryItems} />
            {discoveryEnabled && messages.length === 1 && !hasStartedTyping && (
                <DiscoveryQuestions onQuerySubmit={onQuerySubmit} onQuestionButtonClick={onQuestionButtonClick} />
            )}
            {V2ExperimentalFeatures && <ChatStatusCard statuses={statuses} />}
        </div>
    );
};
