import * as React from 'react';
import { clsx } from 'clsx';
import { useDrawer } from './useDrawer';
import { HTMLAttributes } from 'react';

export type DrawersContainerSizeType = 'sm' | 'lg' | 'md';

export interface IDrawersContainerProps extends HTMLAttributes<HTMLDivElement> {
    size?: DrawersContainerSizeType;
    containerAriaLabel?: string;
    align: string;
}

export const DrawersContainer = React.forwardRef<HTMLDivElement, IDrawersContainerProps>(
    ({ size = 'md', containerAriaLabel = 'Drawer', children, ...props }, ref) => {
        const { toggleDrawer } = useDrawer();

        return (
            <div
                ref={ref}
                className={clsx(`drawer-${size}`)}
                role="dialog"
                aria-label={containerAriaLabel}
                onClick={toggleDrawer}
                {...props}
            >
                {children}
            </div>
        );
    },
);
