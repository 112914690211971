import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';

export const PanelHeading: React.FC<PropsWithChildren<React.HTMLProps<HTMLDivElement>>> = ({
    className,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className);
    classBuilder.add('panel-heading', true);

    return (
        <div className={classBuilder.build()} {...others}>
            <h3 className="panel-title">{children}</h3>
        </div>
    );
};
