import { useMemo } from 'react';
import { ProductConstants } from '../../constants/ProductConstants';
import { useAppSelector } from '../../redux/app/hooks';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export interface DiscoveryCategory {
    id: string;
    name: string;
    items: string[];
}

export interface DiscoveryState {
    isEnabled: boolean;
    categories: DiscoveryCategory[];
}

const CATEGORY_IDS = ['reports', 'application'] as const;

export const useDiscovery = (): DiscoveryState => {
    const hostContext = useAppSelector((state) => state.hostContext.hostContext);
    const { DynamicReport, EnableDiscoveryUi } = useAppSelector((state) => state.app.serviceInfo.featureFlags);
    const { t } = useTranslation('discovery');

    return useMemo(() => {
        const defaultPlaceholderValues = {
            month: new Intl.DateTimeFormat(i18n.language, { month: 'long' }).format(new Date()),
            year: new Date().getFullYear().toString(),
        };

        const discoveryEnabledProducts = [
            ProductConstants.EAccounting.ProductName.toLowerCase(),
            ProductConstants.CloudPayroll.ProductName.toLowerCase(),
            ProductConstants.Advisor.ProductName.toLowerCase(),
        ];
        const selectedProducts = hostContext.products ?? [];
        const isEnabled =
            DynamicReport &&
            EnableDiscoveryUi &&
            selectedProducts.some((product) => discoveryEnabledProducts.includes(product.name.toLowerCase()));

        if (!isEnabled || !selectedProducts.length) {
            return { isEnabled: false, categories: [] };
        }

        const categories = CATEGORY_IDS.map((id) => {
            let items: string[] = [];

            // Aggregate items from all products
            const productItems = selectedProducts.flatMap((product) => {
                const productName = product.name.toLowerCase();
                const items = t(`${id}.${productName}`, { returnObjects: true, ...defaultPlaceholderValues });
                return Array.isArray(items) ? (items as string[]) : [];
            });
            items = [...new Set(productItems)]; // Remove duplicates

            return {
                id,
                name: t(`categories.${id}`),
                items,
            };
        });

        return { isEnabled, categories };
    }, [hostContext.products, DynamicReport, EnableDiscoveryUi, t]);
};
