import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { getElementId } from '../utils/getElementId';

type ProgressBarSize = 'xs' | 'sm' | 'md';

interface IProgressBarProps {
    value: number;
    max?: number;
    size?: ProgressBarSize;
    label?: string | React.ReactNode;
    overLimitLabel?: string | React.ReactNode;
    ariaLabel?: string | React.ReactNode;
    className?: string;
}

export const ProgressBar = ({
    value,
    max = 100,
    size = 'md',
    label,
    overLimitLabel,
    ariaLabel = '',
    className = '',
}: IProgressBarProps) => {
    const elementId = getElementId('progress-bar');
    const ariaElementId = `${elementId}-aria`;

    const percentage = Math.round((value / max) * 100);
    const isOverLimit = percentage > 100;

    const classBuilder = new ClassNameBuilder(className);
    classBuilder.add('progress');
    classBuilder.add('progress-xs', size === 'xs');
    classBuilder.add('progress-sm', size === 'sm');
    classBuilder.add('over-limit', isOverLimit);

    return (
        <div className={classBuilder.build()}>
            <div
                className={`progress-bar`}
                role="progressbar"
                aria-valuenow={value}
                aria-valuemax={max}
                aria-labelledby={ariaElementId}
                style={{ width: `${isOverLimit ? 100 : percentage}%` }}
            >
                {!isOverLimit && <span className="percentage">{percentage}%</span>}
            </div>
            {!isOverLimit && <div className="progress-label">{label}</div>}
            {isOverLimit && (
                <div className="progress-label">
                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error" /> {overLimitLabel ? overLimitLabel : label}
                </div>
            )}
            <label className="sr-only" id={ariaElementId}>
                {ariaLabel}
            </label>
        </div>
    );
};
