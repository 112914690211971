import { makeStyles, shorthands } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { setFeedbackIsOpen } from '../../redux/features/app/appSlice';
import { useAppDispatch } from '../../redux/app/hooks';

const useClasses = makeStyles({
    btn: {
        backgroundColor: '#FFF',
        cursor: 'pointer',
    },
    'btn-feedback': {
        right: '1rem',
        position: 'absolute',
        ...shorthands.margin('15px 5px'),
        ...shorthands.border('1px', 'solid', '#8A8A8A'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px', '15px'),
        width: '100px',
    },
});

export const FeedbackButton = () => {
    const classes = useClasses();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const openFeedbackDialog = () => {
        dispatch(setFeedbackIsOpen({ isOpen: true, hideRating: false }));
    };

    return (
        <button className={`${classes.btn} ${classes['btn-feedback']}`} onClick={openFeedbackDialog}>
            {t('feedback.triggerButton')}
        </button>
    );
};
