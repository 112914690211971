import * as React from 'react';
import { useIntersection } from 'react-use';
import { ComboboxItemPropsKey } from './models/ComboboxItemPropsKey';
import { clsx } from 'clsx';

export interface IComboboxItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
    itemKey: ComboboxItemPropsKey;
    onClickItem: (key: ComboboxItemPropsKey) => void;
    hasFocus: boolean;
    className?: string;
    id?: string;
}

export const ComboboxItem: React.FC<React.PropsWithChildren<IComboboxItemProps>> = ({
    itemKey,
    onClickItem,
    hasFocus,
    className = '',
    children,
    ...rest
}) => {
    const ref = React.useRef<HTMLLIElement>();
    const intersection = useIntersection(ref, {
        rootMargin: '0px',
        threshold: 1,
    });

    React.useEffect(() => {
        if (hasFocus && intersection?.intersectionRatio < 1) {
            ref.current?.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
                inline: 'nearest',
            });
        }
    }, [hasFocus, intersection]);

    return (
        <li
            className={clsx(className, 'combobox-list-item', { focus: hasFocus })}
            ref={ref}
            role="option"
            onClick={() => onClickItem(itemKey)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    onClickItem(itemKey);
                }
            }}
            aria-selected={hasFocus}
            {...rest}
        >
            {children}
        </li>
    );
};
