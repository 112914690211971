import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import * as FocusTrap from 'focus-trap-react';
import { clsx } from 'clsx';

export type ModalSize = 'modal-default' | 'modal-xl' | 'modal-lg' | 'modal-sm' | 'modal-full';
export type ModalType = 'modal-info' | 'modal-help' | 'modal-success' | 'modal-warning' | 'modal-error';

interface IProps {
    className?: string;
    title: string | ReactNode;
    onModalClose: () => void;
    bodyContent: string | React.ReactNode;
    footerContent?: string | React.ReactNode;
    modalSize?: ModalSize;
    modalType?: ModalType;
    onMount?: () => void;
}

export const Modal: React.FC<IProps> & React.HTMLProps<HTMLDivElement> = ({
    className,
    title,
    onModalClose,
    bodyContent,
    footerContent,
    modalSize,
    modalType,
    onMount,
    ...others
}) => {
    useEffect(() => {
        if (onMount) {
            onMount();
        }
    }, []);

    return (
        <>
            <div className={clsx('modal fade in', className, modalType)} role="dialog" aria-labelledby="modal-title" {...others}>
                <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                    <div className={clsx('modal-dialog', modalSize)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="close" onClick={onModalClose}>
                                    <div className="sr-only">Close</div>
                                </button>
                                <div id="modal-title" className="modal-title">
                                    {title}
                                </div>
                            </div>
                            <div className="modal-body">{bodyContent}</div>
                            <div className="modal-footer">{footerContent}</div>
                        </div>
                    </div>
                </FocusTrap>
            </div>
            <div className="modal-backdrop fade in" />
        </>
    );
};
