import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type PillsItemState = 'active' | 'disabled';

export interface IPillsItemProps {
    className?: string;
    state?: PillsItemState;
}

export const PillsItem: React.FC<React.PropsWithChildren<IPillsItemProps>> = ({ state, className, children }) => {
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add(`${state}`, isDefined(state));

    return (
        <button type="button" className={classBuilder.build()} disabled={state === 'disabled'} aria-selected={state === 'active'}>
            {children}
        </button>
    );
};
