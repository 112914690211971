import * as React from 'react';
import { clsx } from 'clsx';

export interface IDrawersFooterProps {
    className?: string;
}

export const DrawersFooter: React.FC<IDrawersFooterProps> & React.HTMLProps<HTMLDivElement> = ({ className, children }) => {
    return <div className={clsx(className, 'drawer-footer justify-content-end d-flex')}>{children}</div>;
};
