import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import React, { useCallback } from 'react';
import { t } from 'i18next';
import { useChat } from '../../libs/hooks/useChat';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AppState } from '../../AppState';
import Icon from '../utils/Icon';
import { customTokens } from '../../styles';
import { useAttachment } from '../../contexts/AttachmentContext';

const useStyles = makeStyles({
    dragger: {
        width: '100%',
        height: '50px',
        flexShrink: 0,
        zIndex: 999,
        fontWeight: 'bold',
        background: customTokens.spcsBackgroundPrimaryLinear,
        paddingLeft: tokens.spacingHorizontalL,
        paddingRight: tokens.spacingHorizontalL,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderTopLeftRadius: tokens.borderRadiusXLarge,
        borderTopRightRadius: tokens.borderRadiusXLarge,
    },
    headerText: {
        color: 'white',
        fontSize: tokens.fontSizeBase400,
        ...shorthands.margin('0'),
        fontWeight: tokens.fontWeightBold,
        userSelect: 'none', // Prevents text selection while dragging
    },
    buttonContainer: {
        color: '#FFFFFF',
        fontSize: tokens.fontSizeBase200,
        position: 'relative', // Ensure it's above the drag area
        zIndex: 2,
    },
    buttonIcon: {
        '&:hover': {
            cursor: 'pointer',
        },
        '&::before': {
            backgroundColor: '#FFFFFF !important',
        },
        '&:first-child': {
            marginRight: tokens.spacingHorizontalXS,
        },
        '&:last-child': {
            marginLeft: tokens.spacingHorizontalXS,
        },
        '&:not(:last-child):not(:first-child)': {
            marginRight: tokens.spacingHorizontalXS,
            marginLeft: tokens.spacingHorizontalXS,
        },
    },
    previewText: {
        backgroundColor: 'var(--spcs-coral-30)',
        color: 'var(--purple-90)',
        borderRadius: tokens.borderRadiusXLarge,
        padding: '2px 6px',
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightBold,
        margin: '20px',
    },
});

interface IChatRoomInlineHeaderProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
    isVisible?: boolean;
}

export const ChatRoomInlineHeader: React.FC<IChatRoomInlineHeaderProps> = ({ setAppState, isVisible = true }) => {
    const classes = useStyles();
    const chat = useChat();
    const hostContext = useAppSelector((state: RootState) => state.hostContext.hostContext);
    const { V2ExperimentalFeatures } = useAppSelector((state: RootState) => state.app.serviceInfo.featureFlags);
    const { setActiveAttachment } = useAttachment();

    const onNewChat = useCallback(() => {
        void chat.createChat(hostContext).then(() => {
            setActiveAttachment(null, 'closed');
            setAppState(AppState.SettingUserInfo);
        });
    }, [chat, setAppState, hostContext, setActiveAttachment]);

    const handleExit = useCallback(() => {
        window.parent.postMessage(
            {
                event: 'closeIframe',
            },
            '*',
        );
    }, []);

    if (!isVisible) return null;

    return (
        <div className={classes.dragger}>
            <div>
                <Text as="h1" className={classes.headerText}>
                    {t('app.title')}
                </Text>
                {V2ExperimentalFeatures && <span className={classes.previewText}>PREVIEW</span>}
            </div>
            <div className={classes.buttonContainer}>
                <Icon name="add-message" className={classes.buttonIcon} onClick={onNewChat} title={t('app.newChat')} />
                <Icon name="cancel" className={classes.buttonIcon} onClick={handleExit} title={t('app.closeChat')} />
            </div>
        </div>
    );
};
