import * as React from 'react';

export interface ComboboxItemSelectedProps {
    label: string;
    closeLabel: (value: string) => string | React.ReactNode;
    onRemove: () => void;
}

export function ComboboxItemSelected(props: ComboboxItemSelectedProps) {
    const translatedLabel = React.useMemo(() => {
        return props.closeLabel(props.label);
    }, [props]);

    const id = React.useMemo(() => {
        return `combobox-multiple-labeledBy-${props.label}`;
    }, [props.label]);

    return (
        <div className="combobox-selected">
            <span className="combobox-selected-label">{props.label}</span>
            <button type="button" className="clear-btn" aria-labelledby={id} onClick={props.onRemove}>
                <span className="close" />
                <span id={id} className="sr-only">
                    {translatedLabel}
                </span>
            </button>
        </div>
    );
}
