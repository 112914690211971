import * as React from 'react';
import { clsx } from 'clsx';

export interface IComboboxInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref'> {
    onInputChange: (value: string) => void;
    onInputKeyDown: (key: string) => void;
    onClick?: () => void;
    className?: string;
    ariaActivedescendant?: string;
}

export const ComboboxInput = React.forwardRef<HTMLInputElement, IComboboxInputProps>(
    ({ className = '', onInputChange, onInputKeyDown, onClick, ariaActivedescendant = '', ...rest }, ref) => {
        return (
            <div className={clsx(className, 'combobox-input')}>
                <input
                    ref={ref}
                    type="text"
                    spellCheck={false}
                    autoComplete="off"
                    aria-autocomplete="list"
                    onClick={onClick}
                    onChange={(event) => onInputChange(event.target.value)}
                    onKeyDown={(event) => onInputKeyDown(event.key)}
                    aria-activedescendant={ariaActivedescendant}
                    {...rest}
                />
            </div>
        );
    },
);
