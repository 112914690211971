import * as React from 'react';
import { PropsWithChildren, ReactElement } from 'react';
import ClassCollector from '../utils/ClassCollector';
import { NavbarNavMenu } from './NavbarNavMenu';

interface IItemProps {
    id?: string;
    isMenuDrop?: boolean;
    expanded?: boolean;
    expandable?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    href?: string;
    target?: string;
    ariaLabel?: string;
    title?: string;
}

export const NavbarNavMenuItem = (props: PropsWithChildren<IItemProps>) => {
    const classes: string[] = [];
    const linkClasses: string[] = [];

    ClassCollector('menudrop', classes, props.isMenuDrop);
    ClassCollector('active open is-open', classes, props.expanded);
    ClassCollector('dropdown second-level-children', classes, props.expandable);
    ClassCollector('nav-item', linkClasses, true);
    ClassCollector('dropdown-toggle', linkClasses, props.expandable || props.isMenuDrop);

    let children = (props.children || []) as ReactElement[];

    if (!Array.isArray(children)) {
        children = [children];
    }

    const MenuBarElement = children.find((child) => child.type === NavbarNavMenu);

    return (
        <>
            <li role="none" className={classes.join(' ')}>
                <a
                    rel="noopener noreferrer"
                    id={props.id}
                    href={props.href}
                    onClick={props.onClick}
                    className={linkClasses.join(' ')}
                    role="menuitem"
                    aria-expanded={props.expanded}
                    aria-label={props.ariaLabel}
                    title={props.title}
                >
                    {children.filter((child) => child.type !== NavbarNavMenu)}
                    {props.expandable && <span className="caret" />}
                    {props.isMenuDrop && (
                        <>
                            <span className="d-sm-none">Menu</span>
                            <i className="icon-align-justify" />
                        </>
                    )}
                </a>
                {MenuBarElement}
            </li>
        </>
    );
};
