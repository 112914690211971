import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { t } from 'i18next';
import { FC, useCallback } from 'react';
import { AppState } from '../../AppState';
import historyIcon from '../../assets/bot-icons/history-btn-icon.svg';
import newChatIcon from '../../assets/bot-icons/new-chat-btn-icon.svg';
import { useChat } from '../../libs/hooks/useChat';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { FeedbackButton } from '../shared/FeedbackButton';

const useClasses = makeStyles({
    roomHeader: {
        display: 'grid',
        gridTemplateColumns: '3fr 2fr',
        width: '100%',
        ...shorthands.borderBottom('1px', 'solid', '#CCC'),
        paddingBottom: '1rem',
        backgroundColor: tokens.colorNeutralBackground3,
        alignItems: 'center',
        '& > *': {
            marginTop: '1rem',
            marginLeft: '1rem',
        },
        '& > a': {
            justifySelf: 'end',
            ...shorthands.textDecoration('none'),
            color: 'black',
            marginRight: '1rem',
        },
    },
    btnGroup: {
        '& > button:last-child': {
            marginLeft: '10px',
        },
    },
    btn: {
        backgroundColor: '#FFF',
        cursor: 'pointer',
    },
    'btn-history': {
        textAlign: 'right',
        backgroundImage: `url(${historyIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '18%',
        backgroundPositionY: '50%',
    },
    'btn-new-chat': {
        textAlign: 'right',
        backgroundImage: `url(${newChatIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '9%',
        backgroundPositionY: '50%',
        ...shorthands.margin('15px 5px'),
        ...shorthands.border('1px', 'solid', '#8A8A8A'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px', '15px', '5px', '35px'),
    },
    'btn-feedback': {
        right: '1rem',
        position: 'absolute',
        ...shorthands.margin('15px 5px'),
        ...shorthands.border('1px', 'solid', '#8A8A8A'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px', '15px'),
        width: '100px',
    },
});

interface IChatRoomHeaderProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

export const ChatRoomHeader: FC<IChatRoomHeaderProps> = ({ setAppState }) => {
    const classes = useClasses();
    const chat = useChat();
    const hostContext = useAppSelector((state: RootState) => state.hostContext.hostContext);

    const onNewChat = useCallback(() => {
        chat.createChat(hostContext)
            .then(() => {
                setAppState(AppState.SettingUserInfo);
            })
            .catch(() => {})
            .finally(() => {});
    }, [chat, setAppState, hostContext]);

    return (
        <div className={classes.roomHeader}>
            <div className={classes.btnGroup}>
                <button
                    className={`${classes.btn} ${classes['btn-new-chat']}`}
                    onClick={onNewChat}
                    data-testid="new-chat-button"
                >
                    {t('app.newChat')}
                </button>
                <FeedbackButton />
            </div>
        </div>
    );
};
