import * as React from 'react';
import { clsx } from 'clsx';

export interface IComboboxButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
    className?: string;
    onButtonKeyDown: (key: string) => void;
}

export const ComboboxButton: React.FC<IComboboxButtonProps> = ({ onClick, className = '', onButtonKeyDown, ...rest }) => {
    return (
        <button
            type="button"
            className={clsx(className, 'combobox-btn')}
            onClick={onClick}
            onKeyDown={(event) => onButtonKeyDown(event.key)}
            {...rest}
        >
            <span className="vismaicon vismaicon-dynamic vismaicon-autocomplete" />
        </button>
    );
};
