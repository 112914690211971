/* eslint-disable no-console */
import * as React from 'react';
import { clsx } from 'clsx';

export interface IDrawersHeaderProps {
    className?: string;
}

export const DrawersHeader: React.FC<IDrawersHeaderProps> & React.HTMLProps<HTMLDivElement> = ({ className, children }) => {
    return <div className={clsx(className, 'drawer-header')}>{children}</div>;
};
