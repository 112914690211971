import * as React from 'react';
import { clsx } from 'clsx';

export interface ITextFieldProps {
    onChange: (value: string) => void;
    className?: string;
    defaultValue?: string;
    placeholder?: string;
    disabled?: boolean;
    hasError?: boolean;
}

export const TextField = React.forwardRef<HTMLTextAreaElement, ITextFieldProps & React.HTMLProps<HTMLTextAreaElement>>(
    ({ defaultValue, hasError, onChange, className, placeholder, disabled, ...other }: ITextFieldProps, ref) => {
        return (
            <textarea
                ref={ref}
                className={clsx(className, 'textarea', hasError && 'has-error')}
                placeholder={placeholder}
                disabled={disabled}
                defaultValue={defaultValue}
                onChange={(event) => onChange(event.target.value)}
                {...other}
            />
        );
    },
);
