import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { ScreenSize } from '../utils/enums/ScreenSize';
import { isDefined } from '../utils/isDefined';

export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ColumnSizeWithAuto = ColumnSize | 'auto';
export type ColumnSizeWithOrder = ColumnSize | 'first' | 'last';

interface IProps {
    className?: string;
    col?: ColumnSizeWithAuto;
    xs?: ColumnSizeWithAuto;
    sm?: ColumnSizeWithAuto;
    md?: ColumnSizeWithAuto;
    lg?: ColumnSizeWithAuto;
    xl?: ColumnSizeWithAuto;
    xxl?: ColumnSizeWithAuto;
    order?: ColumnSizeWithOrder;
    offsetXs?: ColumnSize;
    offsetSm?: ColumnSize;
    offsetMd?: ColumnSize;
    offsetLg?: ColumnSize;
    offsetXl?: ColumnSize;
    offsetXxl?: ColumnSize;
}

const transformCols = (classBuilder: ClassNameBuilder, props: IProps) => {
    classBuilder.add(`col-${props.col}`, isDefined(props.col));

    Object.keys(ScreenSize).forEach((key: string) => {
        const prop = (props as any)[key];
        classBuilder.add(`col-${key}-${prop}`, isDefined(prop));
    });

    classBuilder.add('col', !classBuilder.hasAny());
};

const transformOffset = (classBuilder: ClassNameBuilder, props: IProps) => {
    Object.keys(ScreenSize).forEach((key: string) => {
        const sizeKey = key.charAt(0).toUpperCase() + key.slice(1);
        const prop = (props as any)[`offset${sizeKey}`];

        classBuilder.add(`offset-${key}-${prop}`, isDefined(prop));
    });
};

export const Column: React.FC<PropsWithChildren<IProps> & React.HTMLProps<HTMLDivElement>> = ({
    className,
    col,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    order,
    offsetXs,
    offsetSm,
    offsetMd,
    offsetLg,
    offsetXl,
    offsetXxl,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder();

    transformCols(classBuilder, { col, xs, sm, md, lg, xl, xxl });
    transformOffset(classBuilder, { offsetXs, offsetSm, offsetMd, offsetLg, offsetXl, offsetXxl });

    classBuilder.add(`order-${order}`, isDefined(order)).add(className, isDefined(className));

    return (
        <div className={classBuilder.build()} {...others}>
            {children}
        </div>
    );
};
