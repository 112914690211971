import { makeStyles } from '@fluentui/react-components';
import { t } from 'i18next';

interface TablePageSizeProps {
    pageSize: number;
    onPageSizeChange: (pageSize: number) => void;
}

const useStyles = makeStyles({
    pageSizeContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        height: '32px',
    },
    pageSizeSelect: {
        height: '32px',
        padding: '4px 8px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: 'white',
        cursor: 'pointer',
        minWidth: '70px',
    },
    label: {
        color: '#666',
        fontSize: '14px',
        whiteSpace: 'nowrap',
    },
});

const TablePageSize = ({ pageSize, onPageSizeChange }: TablePageSizeProps): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.pageSizeContainer}>
            <span className={classes.label}>{t('datatable.rowsPerPage')}</span>
            <select
                className={classes.pageSizeSelect}
                value={pageSize}
                onChange={(e) => {
                    onPageSizeChange(parseInt(e.target.value, 10));
                }}
            >
                {[10, 25, 50, 100].map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TablePageSize;
