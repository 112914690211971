import * as React from 'react';
import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

export interface IListContainerProps {
    className?: string;
}

export const ListContainer: React.FC<PropsWithChildren<IListContainerProps> & React.HTMLProps<HTMLSpanElement>> = ({
    children,
    className,
}) => {
    return (
        <div className={clsx(className, 'panel-group')} role="tablist" aria-multiselectable="true">
            {children}
        </div>
    );
};
