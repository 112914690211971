import * as React from 'react';
import { IComboboxTreeNode } from '../models/IComboboxTreeNode';
import { ComboboxItemPropsKey } from '../models/ComboboxItemPropsKey';

export const useComboboxTreeNavigation = (options: IComboboxTreeNode[]) => {
    const [activeItem, setActiveItem] = React.useState<IComboboxTreeNode>(null);

    const findNodeRecursive = (propKey: ComboboxItemPropsKey): IComboboxTreeNode | null => {
        const findByKey = (items: IComboboxTreeNode[], key: ComboboxItemPropsKey): IComboboxTreeNode => {
            for (const node of items) {
                if (node.key === key) {
                    return node;
                }

                if (node.subNodes?.length > 0) {
                    const childNode = findByKey(node.subNodes, key);
                    if (childNode) {
                        return childNode;
                    }
                }
            }

            return null;
        };

        return findByKey(options, propKey);
    };

    const next = (): void => {
        if (options?.length === 0) {
            return;
        }

        if (!activeItem) {
            first();
            return;
        }

        const currentItem = findNodeRecursive(activeItem.key);
        if (!currentItem) {
            first();
            return;
        }

        if (currentItem.subNodes?.length > 0) {
            setActiveItem(currentItem.subNodes[0]);
            return;
        }

        const hasNextSibling = (item: IComboboxTreeNode) => {
            const parentNode = findNodeRecursive(item?.parentId);
            const nodeIndex = parentNode?.subNodes.indexOf(item);
            return parentNode?.subNodes.length > nodeIndex + 1;
        };

        const getNextSibling = (item: IComboboxTreeNode) => {
            const parentNode = findNodeRecursive(item?.parentId);
            const nodeIndex = parentNode.subNodes.indexOf(item);
            return parentNode.subNodes[nodeIndex + 1];
        };

        const getNextSiblingRecurse = (item: IComboboxTreeNode): IComboboxTreeNode => {
            if (!item) {
                return null;
            }
            if (hasNextSibling(item)) {
                return getNextSibling(item);
            }
            const parentNode = findNodeRecursive(item?.parentId);

            return getNextSiblingRecurse(parentNode);
        };

        const nextSibling = getNextSiblingRecurse(currentItem);
        if (nextSibling) {
            setActiveItem(nextSibling);
        } else {
            first();
        }
    };

    const previous = (): void => {
        if (options?.length === 0) {
            return;
        }

        const currentItem = findNodeRecursive(activeItem?.key);
        if (currentItem?.parentId === 0) {
            last();
        } else {
            const parentNode = findNodeRecursive(currentItem?.parentId);
            const currentIndex = parentNode?.subNodes.indexOf(currentItem);
            if (currentIndex > 0) {
                const previousSibling = parentNode.subNodes[currentIndex - 1];
                if (previousSibling.subNodes.length > 0) {
                    const lastChild = previousSibling.subNodes[previousSibling.subNodes.length - 1];
                    setActiveItem(lastChild);
                } else {
                    setActiveItem(previousSibling);
                }
            } else if (currentIndex === 0) {
                setActiveItem(parentNode);
            }
        }
    };

    const first = (): void => {
        if (options?.length > 0) {
            setActiveItem(options[0]);
        }
    };

    const last = (): void => {
        if (options?.length > 0) {
            const findLast = (subNodes: IComboboxTreeNode[]): IComboboxTreeNode => {
                const lastItem = subNodes[subNodes.length - 1];
                if (lastItem.subNodes.length > 0) {
                    return findLast(lastItem.subNodes);
                } else {
                    return lastItem;
                }
            };

            setActiveItem(findLast(options[0].subNodes));
        }
    };

    const clearState = () => {
        setActiveItem(null);
    };

    return [activeItem, clearState, next, previous, first, last] as const;
};
