import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { AppState } from '../../AppState';
import { useAttachment } from '../../contexts/AttachmentContext';
import { AttachmentPanel } from './AttachmentPanel';
import { ChatRoom } from './ChatRoom';
import { ChatRoomHeader } from './ChatRoomHeader';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatRoomInlineHeader } from './ChatRoomInlineHeader';
import { FeedbackDialogOverlay } from '../shared/FeedbackDialogOverlay';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    chatRoom: {
        flex: 1,
        minWidth: '450px',
        overflowY: 'auto',
    },
    chatRoomAttachment: {
        maxWidth: '20%',
    },
    attachment: {
        width: '0',
        transition: 'width 0.3s ease-in-out',
        maxWidth: '80%',
        flex: '1 1 auto',
    },
    attachmentVisible: {
        width: '80%',
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground4,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    popoverHeader: {
        ...shorthands.margin('0'),
        paddingBottom: tokens.spacingVerticalXXS,
        fontStyle: 'normal',
        fontWeight: '600',
    },
    popover: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        ...shorthands.padding(tokens.spacingVerticalXXL),
        ...shorthands.gap(tokens.spacingVerticalMNudge),
        width: '398px',
    },
    input: {
        width: '100%',
    },
    buttons: {
        display: 'flex',
        alignSelf: 'end',
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    alerts: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, '72px'),
    },
});

interface IChatWindowProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

export const ChatWindow: React.FC<IChatWindowProps> = ({ setAppState }) => {
    const classes = useClasses();
    const { activeAttachment, isAttachmentVisible } = useAttachment();
    const { embeddedMode } = useAppSelector((state: RootState) => state.app);

    return (
        <div className={classes.root}>
            <FeedbackDialogOverlay />
            {!embeddedMode && <ChatRoomHeader setAppState={setAppState} />}
            <ChatRoomInlineHeader setAppState={setAppState} isVisible={embeddedMode} />
            <div className={classes.content}>
                {isAttachmentVisible && (
                    <div className={`${classes.attachment} ${classes.attachmentVisible}`}>
                        <AttachmentPanel attachment={activeAttachment.attachment} message={activeAttachment.message} />
                    </div>
                )}
                <div
                    className={
                        isAttachmentVisible
                            ? mergeClasses(classes.chatRoom, classes.chatRoomAttachment)
                            : classes.chatRoom
                    }
                >
                    <ChatRoom />
                </div>
            </div>
        </div>
    );
};
