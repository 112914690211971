import * as React from 'react';
import { clsx } from 'clsx';

export interface ICardsListItemActionProps {
    className?: string;
}

export const CardsListItemAction: React.FC<React.PropsWithChildren<ICardsListItemActionProps>> = ({ children, className }) => {
    return (
        <div className={clsx(className, 'action col-sm-auto col-12 justify-content-center justify-content-sm-end')}>
            <button className="btn">{children}</button>
        </div>
    );
};
