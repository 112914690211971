import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.html5';
import DT, { AjaxData, AjaxDataColumn, InternalSettings } from 'datatables.net-dt';
import DataTable from 'datatables.net-react';
import 'datatables.net-select-dt';
import jszip from 'jszip';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@fluentui/react-components';
import '../../../node_modules/datatables.net-dt/css/dataTables.dataTables.css';
import '../../../src/datatable.css';
import { TableAttachment } from '../../libs/models/ChatMessage';
import { useDataSet } from '../../libs/hooks/useDataSet';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../../libs/models/AlertType';
import { Column } from '../../libs/services/DataSetService';
import ServerDataTable from './datatable/ServerDataTable';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

DataTable.use(DT);
DT.Buttons.jszip(jszip);
DT.Buttons.pdfMake(pdfMake);

interface AiaDataTableProps {
    attachment: TableAttachment;
}

interface ExtendedAjaxDataColumn extends AjaxDataColumn {
    title: string;
    type?: string;
}

const useStyles = makeStyles({
    tableWrapper: {
        height: '100%',
        maxHeight: '80vh',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        contain: 'strict',
        isolation: 'isolate',
    },
    dataTable: {
        flex: 1,
        overflow: 'auto',
    },
});

const AiaDataTable: FC<AiaDataTableProps> = ({ attachment }) => {
    const classes = useStyles();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [prevDatasetReferenceId, setPrevDatasetReferenceId] = useState<string | null>(null);
    const [prevColumnsToShow, setPrevColumnsToShow] = useState<string | null>(null);
    const [columns, setColumns] = useState<ExtendedAjaxDataColumn[]>([]);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { getTableData, getDataSetById } = useDataSet();

    const fetchColumns = useCallback(async () => {
        const datasetReferenceId = attachment.datasetReferenceId;
        if (datasetReferenceId) {
            try {
                const dataSetResponse = await getDataSetById(datasetReferenceId);
                setPrevColumnsToShow(attachment.columnsToShow);
                const columnsToShow = attachment.columnsToShow.split(',').map((s) => s.trim());
                const columns: ExtendedAjaxDataColumn[] = columnsToShow.map((column: string) => {
                    const columnInfo = dataSetResponse.columns?.find((col: Column) => col.name === column);

                    return {
                        name: column,
                        data: column,
                        orderable: true,
                        searchable: true,
                        search: { value: '', regex: false },
                        title: columnInfo?.label ?? column,
                    };
                });

                setColumns(columns);
            } catch (_error: unknown) {
                dispatch(
                    addAlert({
                        message: t('alerts.tableDataError'),
                        type: AlertType.Error,
                    }),
                );
            }
        }
    }, [attachment, getDataSetById, t, dispatch]);

    const ajaxCallback = useCallback(
        async (data: AjaxData, callback: (data: unknown) => void, settings: InternalSettings): Promise<void> => {
            const datasetReferenceId = attachment.datasetReferenceId;
            if (datasetReferenceId) {
                try {
                    if (data.order.length > 0) {
                        // Get data from server but with potentially incorrect sorting
                        await getTableData(
                            data,
                            datasetReferenceId,
                            (result: unknown) => {
                                callback(result);
                            },
                            settings,
                        );
                    } else {
                        // If no sorting specified, use standard call
                        await getTableData(data, datasetReferenceId, callback, settings);
                    }
                } catch (error: unknown) {
                    dispatch(
                        addAlert({
                            message: t('alerts.tableDataError'),
                            type: AlertType.Error,
                        }),
                    );
                    console.error('Error fetching table data:', error);
                }
            }
        },
        [attachment, getTableData, t, dispatch],
    );

    useEffect(() => {
        const fetchColumnsAsync = async () => {
            if (
                attachment.datasetReferenceId !== prevDatasetReferenceId ||
                attachment.columnsToShow !== prevColumnsToShow
            ) {
                setPrevDatasetReferenceId(attachment.datasetReferenceId);
                setColumns([]); // Reset columns before fetching new ones
                await fetchColumns(); // Fetch columns when datasetReferenceId changes
            }
        };

        void fetchColumnsAsync();
    }, [
        attachment.datasetReferenceId,
        prevDatasetReferenceId,
        fetchColumns,
        attachment.columnsToShow,
        prevColumnsToShow,
    ]);

    if (columns.length > 0) {
        return (
            <div className={classes.tableWrapper} ref={wrapperRef}>
                <ServerDataTable
                    columns={columns}
                    columnsToShow={attachment.columnsToShow}
                    onDataFetch={ajaxCallback}
                />
            </div>
        );
    }
    return null;
};

export default AiaDataTable;
