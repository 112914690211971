import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { customTokens } from '../../../styles';
import { useState } from 'react';
import { ChevronLeftRegular } from '@fluentui/react-icons';
import { Trans, useTranslation } from 'react-i18next';
import { useDiscovery, DiscoveryCategory } from '../../../libs/hooks/useDiscovery';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '64px',
        paddingRight: '64px',
        gap: '8px',
        alignItems: 'flex-start',
    },
    categoriesContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '8px',
    },
    category: {
        ...shorthands.margin('4px'),
        ...shorthands.padding('8px'),
        userSelect: 'none',
        cursor: 'pointer',
        backgroundColor: tokens.colorNeutralBackground1,
        color: customTokens.spcsNeutral90,
        borderRadius: tokens.borderRadiusXLarge,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        '&selectors': {
            '&:empty': {
                visibility: 'hidden',
            },
        },
        ':hover': {
            backgroundColor: '#EEE7F9',
        },
    },
    subItem: {
        ...shorthands.padding('8px'),
        userSelect: 'none',
        cursor: 'pointer',
        color: customTokens.colorDiscovery,
        boxSizing: 'border-box',
        ...shorthands.border('1px', 'solid', 'transparent'),
        ':hover': {
            backgroundColor: '#EEE7F9',
            borderRadius: tokens.borderRadiusXLarge,
            ...shorthands.border('1px', 'solid', customTokens.spcsPurple50),
        },
    },
    selected: {
        backgroundColor: '#EEE7F9',
        color: '#252626',
    },
    subItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: 'pointer',
        color: tokens.colorBrandForeground1,
        textDecoration: 'none',
        ...shorthands.margin('0', '0', '8px', '0'),
        ':hover': {
            textDecoration: 'underline',
        },
    },
    backButtonText: {
        fontSize: tokens.fontSizeBase300,
    },
    itemHighlight: {
        color: customTokens.colorDiscoveryHighlight,
        fontWeight: tokens.fontWeightBold,
    },
});

interface CategoryItemProps {
    category: DiscoveryCategory;
    isSelected: boolean;
    onSelect: (category: DiscoveryCategory) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ category, isSelected, onSelect }) => {
    const classes = useClasses();
    return (
        <div
            className={`${classes.category} ${isSelected ? classes.selected : ''}`}
            onClick={() => {
                onSelect(category);
            }}
        >
            {category.name}
        </div>
    );
};

interface SubItemProps {
    item: string | { shortHand: string; prompt: string };
    category: DiscoveryCategory;
    onSelect: (item: string) => void;
}

const SubItemComponent: React.FC<SubItemProps> = ({ item, onSelect, category }) => {
    const classes = useClasses();
    const { t } = useTranslation('discovery');

    // Handle the new format for reports
    if (category.id === 'reports' && typeof item === 'object') {
        return (
            <div
                className={classes.subItem}
                onClick={() => {
                    onSelect(item.prompt);
                }}
            >
                <Trans
                    t={t}
                    i18nKey="fetchInformation"
                    values={{ item: item.shortHand }}
                    components={[
                        <span key={item.shortHand} className={classes.itemHighlight}>
                            {item.shortHand}
                        </span>,
                    ]}
                />
            </div>
        );
    }

    // Handle existing format for non-reports
    if (typeof item === 'string') {
        return (
            <div
                className={classes.subItem}
                onClick={() => {
                    onSelect(item);
                }}
            >
                {item}
            </div>
        );
    }

    return null;
};

interface DiscoveryQuestionsProps {
    onQuerySubmit: (query: string) => void;
    onQuestionButtonClick: (text: string) => void;
}

export const DiscoveryQuestions: React.FC<DiscoveryQuestionsProps> = ({ onQuestionButtonClick, onQuerySubmit }) => {
    const classes = useClasses();
    const [selectedCategory, setSelectedCategory] = useState<DiscoveryCategory | null>(null);
    const { isEnabled, categories } = useDiscovery();
    const { t } = useTranslation('discovery');

    const handleCategorySelect = (category: DiscoveryCategory) => {
        setSelectedCategory(category);
    };

    const handleSubItemSelect = (item: string) => {
        onQuestionButtonClick(item);
        onQuerySubmit(item);
    };

    const handleBack = () => {
        setSelectedCategory(null);
    };

    if (!isEnabled) {
        return null;
    }

    return (
        <div className={classes.container}>
            {selectedCategory ? (
                <>
                    <a
                        className={classes.backButton}
                        onClick={(e) => {
                            e.preventDefault();
                            handleBack();
                        }}
                        href="#"
                    >
                        <ChevronLeftRegular />
                        <span className={classes.backButtonText}>{t('back')}</span>
                    </a>
                    <div className={classes.subItemsContainer}>
                        {selectedCategory.items.map((item) => (
                            <SubItemComponent
                                category={selectedCategory}
                                key={item}
                                item={item}
                                onSelect={handleSubItemSelect}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className={classes.categoriesContainer}>
                    {categories.map((category) => (
                        <CategoryItem
                            key={category.id}
                            category={category}
                            isSelected={false}
                            onSelect={handleCategorySelect}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
