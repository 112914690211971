import * as React from 'react';
import { clsx } from 'clsx';

export interface IComboboxClearIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClearClick: () => void;
    isVisible: boolean;
    className?: string;
}

export const ComboboxClearIcon: React.FC<IComboboxClearIconProps> = ({ onClearClick, isVisible, className = '', ...rest }) => {
    return (
        <button type="button" onClick={onClearClick} className={clsx(className, 'clear-btn', { show: isVisible })} {...rest}>
            <span className="close" />
        </button>
    );
};
