import * as React from 'react';

export const useListNavigation = <T>(options: T[]) => {
    const [activeItem, setActiveItem] = React.useState<T>(null);

    const next = (): void => {
        if (options?.length === 0) {
            return;
        }

        const currentItem = options.indexOf(activeItem);
        if (currentItem === options.length - 1 || currentItem === -1) {
            first();
        } else {
            setActiveItem(options[currentItem + 1]);
        }
    };
    const previous = (): void => {
        if (options?.length === 0) {
            return;
        }

        const currentItem = options.indexOf(activeItem);
        if (currentItem === 0) {
            last();
        } else {
            setActiveItem(options[currentItem - 1]);
        }
    };
    const first = (): void => {
        if (options?.length > 0) {
            setActiveItem(options[0]);
        }
    };
    const last = (): void => {
        if (options?.length > 0) {
            setActiveItem(options[options.length - 1]);
        }
    };

    const clearState = () => {
        setActiveItem(null);
    };

    return [activeItem, clearState, next, previous, first, last] as const;
};
