import * as React from 'react';
import { DropArea } from './DropArea';
import { DropAreaPreview } from './DropAreaPreview';
import { IFileUploadAttachment } from './models/IFileUploadAttachment';
import { DropzoneSize } from './interfaces';
import useFileValidation, { FileVerifyError } from './hooks/useFileValidation';

export interface FileUploadProps {
    value: IFileUploadAttachment[];
    addFiles: (attachments: IFileUploadAttachment[]) => void;
    deleteFile: (attachment: IFileUploadAttachment) => void;
    maxFileSizeMb: number;
    supportedFileTypes: string[];
    supportedFileExtensions: string[];
    dropzoneSize?: DropzoneSize;
    customBody?: React.ReactNode;
    onValidationError?: (errors: FileVerifyError[]) => void;
    onDownloadFile?: (attachment: IFileUploadAttachment) => Promise<File>;
    hideSize?: boolean;
    showSpinner?: boolean;
    labels?: {
        onDeleteLabel?: (attachment: IFileUploadAttachment) => string | React.ReactNode;
        onDownloadLabel?: (attachment: IFileUploadAttachment) => string | React.ReactNode;
        onDownloadInProgressLabel?: (attachment: IFileUploadAttachment) => string | React.ReactNode;
        dropFilesLabel?: string | React.ReactNode;
        kiloByteLabel?: string | React.ReactNode;
        dropAreaHintLabel?: string | React.ReactNode;
    };
    hasErrors?: boolean;
}

export const FileUpload = ({
    value,
    maxFileSizeMb,
    supportedFileTypes,
    supportedFileExtensions,
    addFiles,
    deleteFile,
    dropzoneSize,
    customBody,
    onValidationError,
    onDownloadFile,
    hideSize,
    showSpinner,
    labels,
    hasErrors,
}: FileUploadProps) => {
    const { getValidFiles } = useFileValidation({
        supportedFileMimeTypes: supportedFileTypes,
        fileExtensions: supportedFileExtensions,
        files: value,
        maxFileSizeMb,
    });

    const transformFile = (file: File): IFileUploadAttachment => {
        const { name, size, type } = file;
        return {
            size,
            displayName: name,
            canonicalName: name,
            contentType: type,
            file,
        };
    };

    const handleUpload = async (pendingFiles: File[]) => {
        const [validFiles, errors] = getValidFiles(pendingFiles);

        if (errors?.length > 0 && onValidationError) {
            onValidationError(errors);
        }
        if (validFiles?.length > 0) {
            const attachments = validFiles.map(transformFile);
            addFiles(attachments);
        }
    };

    return (
        <>
            <DropArea
                onUpload={handleUpload}
                dropFilesLabel={labels?.dropFilesLabel}
                size={dropzoneSize}
                hintText={labels?.dropAreaHintLabel}
                customBody={customBody}
                showSpinner={showSpinner}
            />
            <DropAreaPreview
                attachments={value}
                onDownloadFile={onDownloadFile}
                onDelete={deleteFile}
                kiloByteLabel={labels?.kiloByteLabel}
                onDeleteLabel={labels?.onDeleteLabel}
                onDownloadLabel={labels?.onDownloadLabel}
                onDownloadInProgressLabel={labels?.onDownloadInProgressLabel}
                hideSize={hideSize}
                hasErrors={hasErrors}
            />
        </>
    );
};
