import * as React from 'react';
import { clsx } from 'clsx';

export type CardsListType = 'primary' | 'secondary';

export interface ICardsListProps {
    className?: string;
    type?: CardsListType;
}

export const CardsList: React.FC<React.PropsWithChildren<ICardsListProps>> = ({ className, children, type = 'default' }) => {
    return <div className={clsx(className, `card-list card-list-${type}`)}>{children}</div>;
};
