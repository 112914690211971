import * as React from 'react';
import { clsx } from 'clsx';

export type ButtonStyle = 'button' | 'link' | 'no-style';
export type ButtonType = 'default' | 'primary';
export type ButtonSize = 'normal' | 'large';
export type ButtonDirection = 'left' | 'right';

export interface ButtonProps extends Omit<React.ComponentProps<'button'>, 'ref'> {
    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    buttonDirection?: ButtonDirection;
    buttonStyle?: ButtonStyle;
    fullSized?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
        className = '',
        buttonType = 'default',
        buttonSize = 'normal',
        buttonStyle = 'button',
        fullSized = false,
        buttonDirection = '',
        ...props
    },
    ref,
) {
    return (
        <button
            className={clsx(
                { btn: buttonStyle !== 'no-style' },
                { 'btn-primary': buttonType === 'primary' },
                { 'btn-lg': buttonSize === 'large' },
                { 'btn-link': buttonStyle === 'link' },
                { 'btn-block': fullSized },
                buttonDirection,
                className,
            )}
            ref={ref}
            type="button"
            {...props}
        >
            {props.children}
        </button>
    );
});
