import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

type PillsType = 'primary';
type PillsSizeType = 'lg';

export interface IPillsProps {
    className?: string;
    type?: PillsType;
    size?: PillsSizeType;
}

export const Pills: React.FC<React.PropsWithChildren<IPillsProps>> & React.HTMLProps<HTMLDivElement> = ({
    className,
    type,
    size,
    children,
}) => {
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('nav nav-pills').add(`nav-pills-${type}`, isDefined(type)).add(`nav-pills-${size}`, isDefined(size));

    return <div className={classBuilder.build()}>{children}</div>;
};
