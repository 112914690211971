import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AjaxData, AjaxDataColumn, InternalSettings } from 'datatables.net-dt';

import { addAlert } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import DataTable from './DataTable';

interface ExtendedAjaxDataColumn extends AjaxDataColumn {
    title: string;
    type?: string;
    label?: string;
}

interface ServerDataTableProps<T extends Record<string, unknown>> {
    columns: ExtendedAjaxDataColumn[];
    columnsToShow: string;
    onDataFetch: (data: AjaxData, callback: (data: unknown) => void, settings: InternalSettings) => Promise<void>;
    onSelectionChange?: (selectedItems: T[]) => void;
}

const ServerDataTable = <T extends Record<string, unknown>>({
    columns,
    columnsToShow,
    onDataFetch,
}: ServerDataTableProps<T>): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState<T[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortConfig, setSortConfig] = useState<{ column: string; direction: 'asc' | 'desc' } | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = useCallback(
        async (page: number, size: number, sortColumn?: string, sortDirection?: 'asc' | 'desc', search?: string) => {
            setIsLoading(true);
            try {
                // Convert our simple parameters to DataTables.net format
                const ajaxData: AjaxData = {
                    draw: 1,
                    start: (page - 1) * size,
                    length: size,
                    search: {
                        value: search ?? '',
                        regex: false,
                    },
                    order: sortColumn
                        ? [
                              {
                                  column: columns.findIndex((col) => col.name === sortColumn),
                                  dir: sortDirection ?? 'asc',
                              },
                          ]
                        : [],
                    columns: columns.map((col) => ({
                        data: col.name,
                        name: col.name,
                        searchable: true,
                        orderable: true,
                        search: {
                            value: '',
                            regex: false,
                        },
                    })),
                    data: [], // Required by AjaxData type
                };

                // Create a callback function that will handle the response
                const callback = (result: unknown) => {
                    const typedResult = result as { data: T[]; recordsTotal: number; recordsFiltered: number };
                    setData(typedResult.data);
                    setTotalItems(search ? typedResult.recordsFiltered : typedResult.recordsTotal);
                };

                // Create a mock settings object
                const settings = {} as InternalSettings;

                // Call the original onDataFetch with the DataTables.net format
                await onDataFetch(ajaxData, callback, settings);
            } catch (error) {
                dispatch(
                    addAlert({
                        message: t('alerts.tableDataError'),
                        type: AlertType.Error,
                    }),
                );
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        },
        [onDataFetch, columns, dispatch, t],
    );

    useEffect(() => {
        void fetchData(currentPage, pageSize, sortConfig?.column, sortConfig?.direction, searchTerm);
    }, [currentPage, pageSize, sortConfig, searchTerm, fetchData]);

    const handlePageChange = useCallback((page: number): void => {
        setCurrentPage(page);
    }, []);

    const handlePageSizeChange = useCallback((size: number): void => {
        setPageSize(size);
        setCurrentPage(1);
    }, []);

    const handleSort = useCallback((column: string, direction: 'asc' | 'desc'): void => {
        setSortConfig({ column, direction });
    }, []);

    const handleSearch = useCallback((term: string): void => {
        setSearchTerm(term);
        setCurrentPage(1);
    }, []);

    const tableColumns = useMemo(
        () =>
            columns
                .filter((col) =>
                    columnsToShow
                        .split(',')
                        .map((s) => s.trim())
                        .includes(col.name),
                )
                .map((col) => ({
                    name: col.name,
                    title: col.title,
                    type: (col.type as 'string' | 'number' | 'date' | 'boolean' | undefined) ?? 'string',
                    sortable: true,
                    searchable: true,
                })),
        [columns, columnsToShow],
    );

    return (
        <DataTable<T>
            columns={tableColumns}
            data={data}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onSort={handleSort}
            onSearch={handleSearch}
            onPageSizeChange={handlePageSizeChange}
            isLoading={isLoading}
        />
    );
};

export default ServerDataTable;
