import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsSv from './translations/translations.sv.json';
import translationsEn from './translations/translations.en.json';
import translationsNl from './translations/translations.nl.json';
import discoverySv from './translations/discovery.sv.json';
import discoveryEn from './translations/discovery.en.json';
import discoveryNl from './translations/discovery.nl.json';

// init i18next to have two resources loaded (translations)
void i18n.use(initReactI18next).init({
    resources: {
        sv: {
            translations: translationsSv as Resource,
            discovery: discoverySv,
        },
        en: {
            translations: translationsEn as Resource,
            discovery: discoveryEn,
        },
        nl: {
            translations: translationsNl as Resource,
            discovery: discoveryNl,
        },
    },
    fallbackLng: 'sv',
    debug: false,

    // have a common namespace used around the full app
    // get environment variable to determine which namespace to use

    ns: ['translations', 'discovery'],
    fallbackNS: 'translations',
    defaultNS: 'translations',

    interpolation: {
        escapeValue: false,
    },
});

['sv', 'en', 'nl'].forEach((lang) => {
    import(`../src/translations/${process.env.REACT_APP_APPLICATIONNAME ?? ''}.${lang}.json`)
        .then((appSpecificTranslations: Resource) => {
            i18n.addResourceBundle(lang, 'appSpecific', appSpecificTranslations.default);
            i18n.setDefaultNamespace('appSpecific');
        })
        .catch(() => {
            console.warn(`Could not load ${lang} translation`);
        });
});

export default i18n;
